/*
TABLE OF CONTENTS
01- MAIN
02- HEADER
03- SLIDER
04- PROCESS
05- SERVICES
06- APP
07- PRIVILEGES
08- LOCATIONS
09- COMMENTS
10- FAQ
11- FOOTER
99- RESPONSIVE

*/

/* 01- MAIN */
:root {
  --primary-blue: #4d66ff;
  --primary-orange: #ffa900;

  --secondary-blue: #142fe4;
  --secondary-pink: #fc9374;
  --secondary-red: #d6201d;
  --secondary-green: #46f86d;
  --tertiary-black: #151517;
  --tertiary-dark-gray: #676767;
  --tertiary-light-gray: #e8e8e8;
  --tertiary-pale-gray: #f1f1f1;
}

body,
html {
  font-family: "Montserrat", sans-serif;
  color: #2e2e2e;
}

.container {
  padding: 0 6%;
}
input[type="radio"] {
  appearance: auto; /* Reset any custom appearance */
  -webkit-appearance: radio; /* Ensure Safari shows the default radio button */
  visibility: visible; /* Make sure it's visible */
}
.breadcrumbs {
  padding: 10px 0px;
  text-align: center;
}
#firstFree {
  color: #32ca53 !important;
  font-weight: 400;
  font-size: 12px;
}
.ad-banner {
  background-color: #dde1ff;

  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  height: 40px;
}
.courier-banner {
  background-color: hsla(40, 100%, 59%, 0.694);

  height: 50px;
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
}
.courier-banner span {
  text-align: center;
  font-weight: bold;
}
.banner-button {
  font-size: 10px !important;
  background-color: var(--primary-blue);
  color: #fff;
  border: 0;
  border-radius: 9px;
  min-height: 30px !important;
  padding: 2px 20px;
}
a {
  text-decoration: none;
  transition: color 0.5s;
}
.forgot-password {
  padding: 0 100px;
}
.register-button-area {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.divider {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.main-nav.web ul li {
  font-size: 14px;
}
.slick-slide *:focus {
  outline: none !important;
}

/* Ensure divs inside Slick slides also do not have outlines */
.slick-slide div {
  outline: none !important;
  cursor: pointer;
}
.ecommerce-slider {
  max-width: 483px;
  padding-left: 20px;
  padding-right: 20px;
}

.ecommerce-slider .slick-next,
.slick-prev {
  width: 20px !important;
  height: 20px !important;
}
.ecommerce-slider .slick-active {
  width: 100px !important;
}
/* .ecommerce-slider .slick-track {
  width: 250px !important;
} */
.ecommerce-slider .slick-prev {
  left: -14px !important;
  z-index: 1;
}
.ecommerce-slider .slick-next {
  right: -8px !important;
  z-index: 1;
}
/* Additional specificity for any potential active/focus states */
.slick-slide:focus,
.slick-slide:active,
.slick-slide div:focus,
.slick-slide div:active {
  outline: none !important;
}
.slick-next:before,
.slick-prev:before {
  color: var(--primary-blue) !important;
}
.price-sum {
  color: var(--primary-blue) !important;
}
.productContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 10px;
}
.productInformationContainer {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  min-width: 250px;
  flex-direction: column;
  background-color: #f4f4f4;
  align-items: flex-start;
  /* background-color: red; */
  border: 2px solid #e8e8e8;

  justify-content: space-evenly;
  height: 100%;
  align-items: flex-start;
}
/* .productContainer div {
  max-width: 450px !important;
} */
.divider span {
  flex: 1;
  border-bottom: 1px solid var(--tertiary-light-gray);
}
.extra-product-img {
  width: 180px;
}
.ecommerce-order-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
}

.ecommerce-order-card {
  display: flex;
}

.ecommerce-order-info {
  margin-bottom: 20px;
}

.ecommerce-order-info p {
  margin: 5px 0;
}
.product-info img {
  width: 60px;
  height: 60px;
}
.ecommerce-order-products {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ecommerce-product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.ecommerce-product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.ecommerce-product-info p {
  text-align: center;
  font-size: 1px;
  margin: 0;
}

.product-title {
  /* margin-bottom: 5px; */
  font-size: 20px !important;
  font-weight: 600;
  color: var(--tertiary-dark-gray);
}
.carousel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3e57fe;
  overflow: hidden;
  height: 337px;

  padding: 82px;
}
.product-price {
  font-size: 25px !important;
  font-weight: 600;
}
.add-product {
  margin-top: 10px;
  padding: 10px 25px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 14px;
}
.add-product:disabled {
  opacity: 0.6;
  background-color: #2e2e2e;
}
.add-product:hover {
  background-color: #23f350;
}
.e-commerce-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.add-product:disabled:hover {
  /* Your styles for the disabled hover state here */
  /* For example, you can adjust the opacity */
  opacity: 0.6;
  background-color: #2e2e2e;
}
.add-product.active {
  background-color: var(--secondary-green);
  padding: 10px 25px;
  border-radius: 15px;
  font-weight: 600;
}
.add-product.active:hover {
  background-color: #23f350;
}
.invoice-label-text {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  justify-content: space-between !important;
  margin: 0px !important;
}
.product-quantity-container {
  background-color: #fff;
  /* padding: 10px 30px; */
  margin-top: 10px;
  padding: 0 25px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
}
.upper-footer {
  background: url(./utils/img/bg/upper-Footer-Grey.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 66px 50px;
  align-items: center;
}
.upper-footer h3 {
  font-size: 48px;
  text-align: center;
}
.product-quantity {
  background-color: var(--primary-blue);
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.remove-button,
.add-button {
  border: none;
  background-color: transparent;
  font-size: 25px;
  font-weight: 600;
}
.extra-product-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.product-container {
  display: flex;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 30px 15px;
  margin: 30px 0;
  gap: 15px;
}
.product-container img {
  border-radius: 20px;
  border: 1px solid var(--tertiary-light-gray);
}
.discount-title {
  font-size: 16px;
  font-weight: 600;
  color: #ffff;
}
.discount-title.price {
  position: absolute;
  top: 20%;
  left: 20%;
}
.campaign-label {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400 !important;
  gap: 10px;
  font-size: 14px !important;
  justify-content: space-between;
  margin: 0px;
}
.products-container {
  border: 1px solid var(--tertiary-light-gray);
  border-radius: 12px;
  padding: 20px;
}
.discount-title.info {
  position: absolute;
  font-size: 14px;
  top: 50%;
  left: 15%;
  transform: rotate(-7deg);
}
.welcome-text {
  font-size: 14px !important;
  font-weight: 450;
}
.mobile {
  display: none !important;
}

.service-selection-main {
  justify-content: space-between !important;
}
.kvkk-table {
  border-collapse: collapse;
  border: 1px solid black;
}
.error-title {
  font-size: 180px;
  color: var(--primary-blue);
}
.privilege-button:first-of-type {
  padding: 0px 86px;
}
header #main-nav.mobile-nav {
  visibility: hidden !important;
  display: block;
  position: absolute;
  left: 0;
  top: 110px;
  min-width: 300px;
  overflow: hidden;
  opacity: 0;
  padding: 29px 25px;
  background-color: #fff;
  border-radius: 24px;
  -webkit-box-shadow: 0px -1px 13px 4px rgb(0 0 0 / 5%);
  box-shadow: 0px -1px 13px 4px rgb(0 0 0 / 5%);
  transition: opacity 0.5s, visibility 0.5s;
}
.mobile-menu:hover #main-nav {
  /* display: block; */
  opacity: 1;
  visibility: visible !important;
}
.ecommerce-products {
  /* max-width: 1200px; */
  margin: 0 auto;
  justify-content: center;
  display: grid;
  gap: 1rem;
}
.flex-container {
  display: flex;
  /* justify-content: center; */
  gap: 10px;

  align-items: center;
  flex-wrap: nowrap;
}

.bedelli-tutar {
  background-color: yellow;
}

table.kvkk-table td,
th {
  border: 1px solid black;
  padding: 8px;
}
.price-sum-integer {
  font-size: 24px;
}
.divider small {
  margin: 0 50px;
  color: var(--tertiary-dark-gray);
  font-size: 12px;
}
.total-sum {
  height: 40px;
}
.button {
  padding: 0 32px;
  font-weight: 700;
  display: inline-block;
  min-height: 60px;
  line-height: 60px;
  vertical-align: middle;
  font-size: 16px;
  border-radius: 15px;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}
.form-control {
  border: none !important;
  background-color: var(--tertiary-pale-gray) !important;
}
.font-500 {
  font-weight: 500 !important;
}
.order-information-title {
  color: var(--tertiary-dark-gray, #676767);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0;
}
.button-previous {
  padding: 9px 20px;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
  border: 1px solid var(--tertiary-dark-gray);
  background-color: white;
  border-radius: 15px;
}

.slick-slide > div {
  display: grid;
  place-items: center;
  /* width: 80%; */
  margin-top: 50px;
  margin: auto;
  /* height: 500px; */
  padding: 0px;
}

.oku-link {
  color: var(--primary-blue);
}
.product-item {
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid var(--Grey3, #f1f1f1);
  background: var(--White, #fff);
  height: 335px;
  flex-shrink: 0;
  width: 247px;
}
.product-item:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the values to your preference */
}
.product-item .slick-track {
  max-height: 250px !important;
}
.product-image {
  max-height: 172px;
  height: 100%;
  width: 202px;
  overflow: hidden;
}

.product-image img {
  object-fit: cover;
  max-width: 202px;
  height: 172px;

  border-radius: 10px 10px 0 0;
}
.product-image-detail img {
  object-fit: cover;
  width: 350px;
  height: 350px;
  border-radius: 10px 10px 0 0;
}
.product-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  max-height: 125px;
}

.product-name {
  color: var(--Gray-900, #191c1f);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  max-height: 45px;
  line-height: 20px; /* 142.857% */
}
.color-options {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}
.color-option {
  width: 20px;
  cursor: pointer;
  height: 20px;
  border-radius: 50%;
  transition: all 0.3s ease;
  border: 2px solid rgb(176, 173, 173);
}
.color-option.details {
  width: 36px;
  height: 36px;
}
.color-option:hover {
  transform: scale(1.1); /* Make it a bit bigger on hover */
  border-color: #007bff; /* Blue border color */
}
.button-new {
  padding: 15px 32px;
  font-weight: 700;
  display: inline-block;

  font-size: 14px;
  border-radius: 15px;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}
.ecommerce-slider .slick-slide div {
  width: 30px;
}
.payment-container {
  display: flex;
  border-radius: 3px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
  max-width: 566px;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.payment-container img {
  max-width: 312px;
  width: 100%;
  height: 34px;
}

.button-primary {
  color: #fff;
  background-color: var(--primary-blue);
}

.button-primary:hover {
  color: #fff;
  background-color: var(--secondary-blue);
}

.blog-container {
  display: flex;
}

.button-secondary {
  color: #fff;
  border: 3px solid #feb72d !important;
  background-color: #feb72d !important;
  /* background-color: #f9a501; */
}

.button-secondary:hover {
  color: #fff;
  background-color: #f9a501 !important;
}
.borderLeft {
  border-left: 1px solid #676767 !important;
  padding-left: 32px !important;
}
.pd-20 {
  padding-left: 32px;
}
.button-ghost {
  color: var(--primary-blue);
  /* background-color: white; */
  font-weight: 700;
  border: 3px solid var(--primary-blue);
  /* line-height: 50px; */
}
.button-ghost-input {
  color: var(--primary-blue);
  background-color: white;
  font-weight: 700;
  border: 2px solid var(--primary-blue);
  line-height: 50px;
  border-radius: 15px !important;
}
.min-total {
  font-size: 14px !important;
  margin-top: 10px;
}
.input-create-order {
  display: flex;
  gap: 10px;
  align-items: center;
}

.button-ghost.text-white {
  border-color: #fff;
}
/*  */

.button.button-menu {
  min-height: 46px;
  line-height: 46px;
}

.button-input {
  min-height: 56px;
  line-height: 56px;
  width: 100%;
}
.cancelled-product-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.product-cancel-btn {
  line-height: normal;

  padding: 15px 10px;
  border-radius: 15px;
}
.otp-input {
  color: #78828a;

  max-width: 50px !important;
  width: 100% !important;
  font-weight: 750;
  padding: 19px;
  background-color: #f1f1f1 !important;
  border-radius: 16px !important;
  border: none;
}

.button-link {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-blue);
}

.button-primary {
  color: #fff;
  background-color: var(--primary-blue);
  border: none;
}

.button-primary:hover {
  color: #fff;
  background-color: var(--secondary-blue);
}

.button-secondary {
  color: #fff;
  background-color: var(--primary-orange);
}

.button-secondary:hover {
  color: #fff;
  background-color: var(--secondary-pink);
}

.privilege-button {
  padding: 0 59px;
}

.button-ghost.text-white {
  border-color: #fff;
  /* min-height: 54.8px; */
  /* line-height: 54.9px; */

  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}

.hizmetlerimiz-container {
  display: flex;
  padding: 0 12px;
  justify-content: center;
  /* align-items: stretch; */
  flex-wrap: wrap;
  gap: 35px;
}

.button-ghost:hover {
  background-color: var(--secondary-blue) !important;
  border-color: var(--secondary-blue);
  color: #fff;
}

.button:disabled {
  background-color: var(--tertiary-light-gray);
  color: #fff;
  border: none;
}

.input {
  width: 100%;
  padding: 16px 28px;
  background-color: var(--tertiary-pale-gray);
  border-radius: 15px !important;
  font-size: 16px;
  border: none;
  color: var(--tertiary-dark-gray);
}
.product-color-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

form input,
.form input {
  width: 100%;
  padding: 16px 28px;
  background-color: var(--tertiary-pale-gray);
  border-radius: 15px;
  font-size: 16px;
  border: none;
  color: var(--tertiary-dark-gray);
}

.order-container-form input {
  width: 100%;
  padding: 16px 28px;
  background-color: var(--tertiary-pale-gray);
  border-radius: 15px;
  font-size: 16px;
  border: none;
  color: var(--tertiary-dark-gray);
}

.order-date-picker input {
  background-color: var(--tertiary-pale-gray) !important;
}

form input:focus,
.form input:focus {
  outline: 2px solid var(--primary-blue);
}

.order-container-form:focus {
  outline: 2px solid var(--primary-blue);
}

.form-area {
  margin-bottom: 34px;
  position: relative;
}

form label,
.form label {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}

.order-container-form label {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}
.product-image-container {
  width: 350px;
  height: 350px;
  background-color: #f4f4f4;
}
form label span,
.form label span {
  color: var(--secondary-red);
}

.button-input {
  min-height: 56px;
  line-height: 56px;
  width: 100%;
}

.cart-icon-container-inner {
  border-radius: 15px;
  min-width: 328px;
  padding-bottom: 20px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.06);
  position: absolute;
  top: 60px;
  right: -35px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.table-price {
  color: var(--Gray-900, #191c1f);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.stock-info {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.stock-info.no {
  color: #5bd366;
}
.stock-info.yes {
  color: var(--Red, #d6201d);
}
.error-text {
  padding: 10px 0;
  text-align: left;
  font-size: 10px;
  color: var(--secondary-red);
}
.check-product-button {
  outline: none;
  padding: 16px 34px;
  border: none;
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 15px;
  background: var(--Primary, #3e57fe);
}
.cart-product {
  position: relative;
  display: flex;
  max-height: 300px;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: scroll;
}
.cart-product p {
  color: var(--Gray-700, #475156);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  margin: 0;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.product-price-count {
  color: var(--Gray-600, #5f6c72);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.product-price-count span {
  color: var(--Primary, #3e57fe);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.cart-total {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 16px 24px 0 24px;
}
.cart-total p:nth-of-type(1) {
  color: var(--Gray-700, #475156);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.cart-total p:nth-of-type(2) {
  color: var(--Gray-900, #191c1f);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-main-slider-image {
  width: 100%;
  border-radius: 10px;
}
.cart-product-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px 24px 24px;
}

.cart-product .cancel-img {
  width: 16px;
  height: 16px;
  border: none;
  margin-left: auto;

  /* position: absolute; */
  /* top: 50%;
  right: -10px; */
}
.cart-product img {
  width: 80px;
  object-fit: cover;
  height: 80px;
  border-radius: 15px;
  border: 1px solid var(--Gray-100, #e4e7e9);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .main-radio-button input[type="radio"]:checked {
  background-color: var(--primary-blue) !important;
  border-color: transparent !important;
  outline: none !important;
} */

.main-radio-button input[type="radio"]::before {
  content: url(./utils/img/icons/checkmark-white.svg) !important;
  position: absolute;
  /* z-index: 1; */
  left: 3.2px;
  top: -3.5px;
  width: 100%;
  height: 100%;
}

.promo-info {
  background-color: #e5f9f3;
  padding: 12px 28px;
  border-radius: 15px;
  /* margin-bottom: 20px !important;
   */
  padding-bottom: 0;
  border: 1px solid var(--tertiary-light-gray);
  display: flex;
  justify-content: space-between;
}

.promo-info p {
  font-size: 16px !important;
}

.term-links {
  color: var(--primary-blue);
  text-decoration: underline;
  cursor: pointer;
}

.promo-info .img-div {
  cursor: pointer;
}

form input[type="checkbox"],
.form input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  min-width: 20px !important;
  max-width: 20px !important;
  height: 20px;
  padding: 4px;
  border-radius: 5px;
  margin-right: 12px;
  background-color: #fff;
  position: relative;
  border: 1px solid var(--tertiary-dark-gray);
  display: grid;
  place-content: center;
}
/* ECOMMERCE */
.favorites {
  display: flex;
  width: inherit;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 24px; */
  border-radius: 15px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
}

.favorites-table {
  width: 100%;
  border-collapse: collapse;
}
.favorites-table th,
.favorites-table td {
  border: none;
  padding: 24px;
  text-align: left;
}
.favorites-table td.actions {
  display: flex;
  margin-right: 20px;
  justify-content: space-around;
}
.favorites-table thead {
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-50, #f2f4f5);
  height: 40px !important;
}
.favorites-table th {
  color: var(--Gray-700, #475156);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  padding: 0 24px !important;
  font-weight: 500;
  text-transform: uppercase;
}
.favorites-table td.actions {
  white-space: nowrap;
}
.favorites-table td.actions button {
  margin-right: 10px;
}
.favorites .favorites-header p {
  color: var(--Gray-900, #191c1f);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding: 24px;
}
.cart-title {
  margin: 0;
  width: 100%;
  padding-bottom: 16px;
  color: var(--Gray-900, #191c1f);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 16px 16px 16px 24px;
  line-height: 24px; /* 150% */
}
.cart-title span {
  color: var(--Gray-600, #5f6c72);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* .terms input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  min-width: 20px !important;
  max-width: 20px !important;
  height: 20px;
  padding: 4px;
  border-radius: 5px;
  margin-right: 12px;
  background-color: var(--tertiary-light-gray);
  position: relative;
  border: 1px solid var(--tertiary-light-gray);
  display: grid;
  place-content: center;
} */
form input[type="radio"],
.form input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  min-width: 20px !important;
  max-width: 20px !important;
  height: 20px;
  padding: 7px !important;
  border-radius: 20px;
  margin-right: 12px;
  margin-top: 12px;
  background-color: #fff;
  position: relative;
  border: 1px solid #3e58fe1e;
  display: grid;
  place-content: center;
}

input[type="checkbox"]:checked {
  background-color: var(--primary-blue);
  border-color: transparent;
  outline: none;
}

/* .terms input[type="checkbox"]:checked {
  content: url(./utils/img/icons/checkmark-white.svg);
  background-color: var(--primary-blue);
  border-color: transparent;
  outline: none;
} */
section input[type="checkbox"]::before {
  content: url(./utils/img/icons/checkmark-white.svg);
  position: absolute;
  z-index: 1;
  left: 3px;
  top: -4px;
  width: 100%;
  height: 100%;
}
.mobile-auth {
  display: none !important;
}

/* .terms input[type="checkbox"]::before {
  content: "";

  position: absolute;
  z-index: 1;
  left: 3px;
  top: -3px;
  width: 100%;
  height: 100%;
} */
.service-selection-main input[type="checkbox"]::before {
  content: "";
}
.slider-service input[type="checkbox"]::before {
  content: "";
}

form select,
.form select {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding: 16px 28px;
  background-color: var(--tertiary-pale-gray);
  border-radius: 15px;
  font-size: 16px;
  border: none;
  color: var(--tertiary-dark-gray);
}

.order-container-form select {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding: 16px 28px;
  background-color: var(--tertiary-pale-gray);
  border-radius: 15px;
  font-size: 16px;
  border: 3px solid var(--tertiary-pale-gray);
  color: var(--tertiary-dark-gray);
}

form select:disabled,
.form select:disabled {
  background-color: #f9f9f9;
}

.order-container-form select:disabled {
  background-color: #f9f9f9;
}

form select::-ms-expand,
.form select::-ms-expand {
  display: none;
}

.order-container-form select::-ms-expand {
  display: none;
}

form select:focus,
.form select:focus {
  outline: 2px solid var(--primary-blue);
}

.order-container-form select:focus {
  outline: 2px solid var(--primary-blue);
}

.input-select {
  width: 100%;
  position: relative;
}

.sort-select::after {
  content: url(/src/utils/img/icons/chevron-down.svg);
  width: 14px;
  height: 8px;
  position: absolute;
  right: 39px;
  top: 18px;
}
form textarea,
.form textarea {
  padding: 19px;
  width: 100%;
  background: #f9f9f9;
  border-radius: 15px;
  border: 1px solid var(--tertiary-light-gray);
}

.order-container-form textarea {
  padding: 19px;
  width: 100%;
  background: #f9f9f9;
  border-radius: 15px;
  border: 1px solid var(--tertiary-light-gray);
}
.addAcartButton {
  cursor: pointer;
  border-radius: 12px;
  padding: 6.6px 10px !important;
  background: #3e57fe;
  color: #fff;
  min-height: 10px !important;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal !important;
}
.commerce-price {
  color: var(--Primary, #3e57fe);
  font-feature-settings: "clig" off, "liga" off;
  /* Button */
  font-family: Montserrat;
  font-size: 16px;
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal !important;
}
.info-box {
  background-color: #cff4fc;
  padding: 16px 28px;
  border-radius: 15px;
  border: 1px solid #b6effb;
  margin-top: 20px;
}

.why-popup {
  display: flex;
  position: absolute;
  background-color: #fff;
  padding: 15px 30px;

  /* width: 200px; */
  border-radius: 14px;
  font-size: 14px !important;
  border: 1px solid var(--tertiary-pale-gray);
  right: 0;
  top: 10px;
}

.confirm-modal-delete {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.why-popup p {
  color: var(--tertiary-dark-gray);
  margin-bottom: 0 !important;
  font-size: 10px !important;
  margin-right: 10px;
  padding-right: 10px;
}

.cancel-why-popup {
  width: 25px;
  cursor: pointer;
}

.search-bar form input,
.search-bar .form input {
  height: 60px;
  width: 673px;
  padding: 16px 42px;
  background-color: var(--tertiary-pale-gray);
  border-radius: 15px;
  border: none;
  margin-right: 16px;
}

.search-bar form button,
.search-bar .form button {
  border: none;
}

section.big-title {
  padding: 119px 0;
}

section.big-title h2,
section.big-title h3,
section.middle-title h2,
section.middle-title h3 {
  font-weight: 600;
  margin-bottom: 26px;
}

section.big-title h2,
section.big-title h3 {
  text-transform: uppercase;
}

section.big-title h2,
section.middle-title h2 {
  font-size: 36px;
}

section.big-title h3,
section.middle-title h3 {
  font-size: 24px;
  margin-bottom: 5px;
}

section.big-title .text-white h3 {
  color: var(--tertiary-light-gray);
}

section.big-title .title p,
section.middle-title .title p {
  font-size: 18px;
  line-height: 30px;
  color: var(--tertiary-dark-gray);
  /* width: 540px; */
}

section.middle-title {
  padding: 80px 0;
}

section.middle-title h2,
section.middle-title h3 {
  text-transform: none;
}

section.middle-title .title p {
  max-width: 450px;
}

section .title {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upper-footer-border {
  border-right: 1px solid #2e2e2e;
}
.light-bg {
  background-color: #f9f9f9;
}

.primary-bg {
  background-color: var(--primary-blue);
}

.bg-element-1 {
  background-position: 0 100%;
  background-repeat: no-repeat;
}

.bg-element-2 {
  /* background-image: url(../img/bg/bg-element-2.png); */
  background-image: url("./utils/img/bg/bg-element-2.png");
  background-position: -20% -40%;
  background-repeat: no-repeat;
}

.bg-element-3 {
  background-image: url(./utils/img/bg/bg-element-3.png);
  background-position: 0 100%;
  background-repeat: no-repeat;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #27275599;
  z-index: 9999;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(2px);
  align-items: center;
}
.invoice-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.invoice-button {
  background-color: #3e58fe1e;
  border: 1px solid #3e58fe00 !important;
  color: var(--primary-blue);
  font-weight: 600;
  padding: 10px 60px;
  border-radius: 10px;
}
.invoice-type-button.active {
  background-color: #3e58fe1e;
  border: 1px solid #3e58fe00 !important;
  color: var(--primary-blue);
}
.video-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.invoice-type-button {
  border: 1px solid #3e58fe1e;
  font-weight: 600;
  padding: 10px 55px;
  border-radius: 10px;
}

.modal-container {
  gap: 10px;
  text-align: center;
  background-color: #fff;
  width: 465px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 411px;
}

.cancel-button {
  border: 1px solid #0d0c0f;
  padding: 10px 54px;
  font-weight: 600;
  border-radius: 12px;
}

.cancel-button:hover {
  background-color: #0d0c0f;
  color: #fff;
}

.confirmation-page {
  margin: 0 50px;
  padding: 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
}

.package-delete-button {
  background-color: #d6201d;
  border: 1px solid #d6201d;
  font-weight: 600;
  border-radius: 12px;
  padding: 10px 24px;
  color: #fff;
}

.package-delete-button:hover {
  background-color: #fff;
  color: #d6201d;
}

.package-confirm-button {
  background-color: var(--primary-orange);
  border: 1px solid var(--primary-orange);
  font-weight: 600;
  border-radius: 12px;
  padding: 10px 24px;
  color: #fff;
}

.package-confirm-button:hover {
  background-color: #fff;
  color: var(--primary-orange);
}

.search-bar form input,
.search-bar .form input {
  height: 60px;
  width: 673px;
  padding: 16px 42px;
  background-color: var(--tertiary-pale-gray);
  border-radius: 15px;
  border: none;
  margin-right: 16px;
}

.search-bar form input:focus,
.search-bar .form input:focus {
  outline: 2px solid var(--primary-blue);
}

.search-bar form button,
.search-bar .form button {
  border: none;
}

/* 01- MAIN END */

/* 02- HEADER */
header {
  position: relative;
  z-index: 99;
}

header .header-main {
  height: 140px;
  padding: 0 6%;
}

header .header-main {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  /* justify-content: center; */
  /* flex-direction: row-reverse; */
}

header nav ul {
  margin-bottom: 0;
}

header nav ul > li {
  display: inline;
  margin-right: 35px;
  font-weight: 700;
  font-size: 14px;
}

header nav ul > li.dropdown {
  position: relative;
}

header nav ul > li:last-of-type {
  margin-right: 0;
}

header nav ul > li a {
  text-decoration: none;
  color: var(--tertiary-black);
  display: inline-block;
}

header nav ul > li.dropdown .dropdown-box {
  display: none;
  position: absolute;
  padding-top: 15px;
  width: 256px;
  left: 0;
}
.mobile-dropdown-box {
  display: none;
  position: absolute;
  padding-top: 15px;
  width: 256px;
  left: -220px !important;
  top: 20px;
}

header nav ul > li.dropdown .dropdown-box .inner,
.mobile-dropdown-box .inner {
  padding: 29px 25px;

  /* z-index: 1000; */

  border-radius: 24px;
  -webkit-box-shadow: 0px -1px 13px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -1px 13px 4px rgba(0, 0, 0, 0.05);
}

header nav ul > li.dropdown .dropdown-box ul {
  padding-left: 0;
}

header nav ul > li.dropdown .dropdown-box ul li,
.mobile-dropdown-box ul li {
  display: block;
  margin-bottom: 24px;
  margin-right: 0;
}

header nav ul > li.dropdown .dropdown-box ul li:last-of-type,
.mobile-dropdown-box ul li:last-of-type {
  margin-bottom: 0;
}

/* header nav ul > li.dropdown:hover .dropdown-box {
  display: block;
} */
header nav ul > li.dropdown > a:after,
.mobile-dropdown-box > a:after {
  content: url(./utils/img/icons/chevron-down.svg);
  width: 10px !important;
  height: 2px;
  margin-left: 5px;
}

header nav ul > li.dropdown .dropdown-box.mega {
  width: 627px;
  left: -200px;
}

header nav ul > li.dropdown .dropdown-box.mega .inner {
  padding: 34px 36px;
  position: relative;
  z-index: 1000;
}

header nav ul > li.dropdown .dropdown-box.mega h3 {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
}

header nav ul > li.dropdown .dropdown-box.mega ul li {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
}

header nav ul > li.dropdown .dropdown-box.mega ul li.all a {
  color: var(--primary-blue);
  font-weight: 500;
  margin-bottom: 24px;
}

header #main-actions .button:first-of-type {
  margin-right: 5px;
}

header nav ul > li.dropdown,
header #main-actions .dropdown {
  position: relative;
}

header nav ul > li a,
header #main-actions .dropdown-box ul li a,
.mobile-dropdown-box ul li a {
  text-decoration: none;
  color: var(--tertiary-black);
  display: inline-block;
  font-size: 13px;
}

header nav ul > li.dropdown .dropdown-box,
header #main-actions .dropdown-box {
  display: none;
  position: absolute;
  padding-top: 15px;
  width: 256px;
  left: 0;
}
.mobile-dropdown-box {
  display: none;
  position: absolute;
  padding-top: 15px;
  width: 256px;
  left: 0;
}

header .mobile-menu {
  cursor: pointer;

  display: none;
  background-image: url(./utils/img/ham-menu.svg);
  /* background-image: url(./utils/img/hamburger.png); */
  width: 35px;
  height: 35px;
  margin-right: -10px;
  background-repeat: no-repeat;
}

#menu-overlay {
  position: absolute;
  height: calc(100% - 140px);
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
}

#main-actions .user-menu {
  padding: 18px 40px 8px 40px;
  background-color: var(--primary-blue);
  border-radius: 15px;
  color: #fff;
  position: relative;
}

#main-actions .user-menu::before,
#main-actions .user-menu::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#main-actions .user-menu::before {
  left: 13px;
  background-image: url(./utils/img/icons/user-white.svg);
  width: 24px;
  height: 24px;
}

#main-actions .user-menu::after {
  right: 13px;
  background-image: url(./utils/img/icons/chevron-down-white.svg);
  width: 28px;
  height: 28px;
}

#main-actions .user-menu h6 {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 2px;
}

#main-actions .user-menu span {
  font-size: 10px;
  margin-bottom: 0;
}

header #main-actions .dropdown-box {
  left: -40px;
}

header #main-actions .dropdown-box ul,
.mobile-dropdown-box ul {
  margin-bottom: 0;
}

#main-actions .dropdown-box ul li a,
.mobile-dropdown-box ul li a {
  padding-left: 39px;
  position: relative;
}

header nav ul > li.dropdown .dropdown-box .inner,
header #main-actions .dropdown-box .inner,
.mobile-dropdown-box .inner {
  padding: 29px 25px;
  background-color: #fff;
  border-radius: 24px;
  -webkit-box-shadow: 0px -1px 13px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -1px 13px 4px rgba(0, 0, 0, 0.05);
}

header nav ul > li.dropdown .dropdown-box ul,
header #main-actions .dropdown-box ul,
.mobile-dropdown-box ul {
  padding-left: 0;
}

header nav ul > li.dropdown .dropdown-box ul li,
header #main-actions .dropdown-box ul li,
.mobile-dropdown-box ul li {
  display: block;
  margin-bottom: 24px;
  margin-right: 0;
}

header nav ul > li.dropdown .dropdown-box ul li:last-of-type,
header #main-actions .dropdown-box ul li:last-of-type,
.mobile-dropdown-box ul li:last-of-type {
  margin-bottom: 0;
}
.mobile-profile {
  cursor: pointer;
}
header nav ul > li.dropdown:hover .dropdown-box,
header #main-actions .dropdown:hover .dropdown-box {
  display: block;
}

.mobile-profile:hover .mobile-dropdown-box {
  display: block;
}

#main-actions .dropdown-box ul li a:hover,
.mobile-dropdown-box ul li a:hover {
  color: var(--primary-blue);
}

.footer-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#main-actions .dropdown-box ul li a::before,
.mobile-dropdown-box ul li a::before {
  content: "";
  margin-right: 15px;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-image 0.5s;
}

#main-actions .dropdown-box ul li a.icon-user::before,
.mobile-dropdown-box ul li a.icon-user::before {
  background-image: url(./utils/img/icons/user-black.svg);
}

#main-actions .dropdown-box ul li a.icon-user:hover::before,
.mobile-dropdown-box ul li a.icon-user:hover::before {
  background-image: url(./utils/img/icons/user-blue.svg);
}

.plus-div {
  background-image: url(./utils/img/icons/plus.svg);
  background-repeat: no-repeat;
  background-position: center left;
}

.plus-div:hover {
  background-image: url(./utils/img/icons/plus-blue.svg);
  z-index: 100;
  background-repeat: no-repeat;
  background-position: center left;
}

.plus-div.active {
  background-image: url(./utils/img/icons/plus-blue.svg);
}

.gift-div {
  background-image: url(./utils/img/icons/gift.svg);
  background-repeat: no-repeat;
  background-position: top right;
}

.twitter {
  cursor: pointer;
  background-image: url(./utils/img/icons/twitter-social.svg);
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center left;
}

.twitter:hover {
  width: 40px;
  height: 40px;
  background-image: url(./utils/img/icons/twitter.png);
  background-repeat: no-repeat;
  background-position: center left;
}

.facebook {
  cursor: pointer;
  background-image: url(./utils/img/icons/facebook-white.svg);
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center left;
}

.facebook:hover {
  width: 40px;
  height: 40px;
  background-image: url(./utils/img/icons/facebook-blue.svg);
  background-repeat: no-repeat;
  background-position: center left;
}

.instagram {
  cursor: pointer;
  background-image: url(./utils/img/icons/instagram-white.svg);
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center left;
}

.instagram:hover {
  width: 40px;
  height: 40px;
  background-image: url(./utils/img/icons/instagram-blue.svg);
  background-repeat: no-repeat;
  background-position: center left;
}

.arrow-div {
  background-image: url(./utils/img/icons/arrow-right-black.svg);
  background-repeat: no-repeat;
  background-position: center right;
}

.arrow-div-gift {
  background-image: url(./utils/img/icons/arrow-facing-right.svg);
  background-repeat: no-repeat;
  background-position: center right;
}

.arrow-div:hover {
  color: var(--primary-blue);
  background-image: url(./utils/img/icons/arrow-facing-right.svg);
  z-index: 100;
  background-repeat: no-repeat;
  background-position: center right;
}
.arrow-div.invoice-container:hover .invoice {
  color: var(--secondary-blue) !important;
}
.btn-order-preview {
  margin-left: 30px;
  font-weight: 600;
}

.image-comparison {
  position: relative;
}

.image-comparison img {
  width: 100%;
  height: auto;
}

.handle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.right-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-comparison:hover .right-image {
  opacity: 1;
}

.promo-button {
  left: calc(100% - 100px);
  top: 8px;
  position: absolute;
  /* width: 70px; */
  font-size: 14px;
  border-radius: 15px !important;
  /* height: px; */
  padding: 10px 15px;
  font-weight: bold;
  border: none;
  background-color: #3e57fe;
  color: white;
}

.promo-button:disabled {
  background-color: var(--tertiary-light-gray);
}
.slider-service-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}
.mobile-button-auth {
  font-size: 14px !important;
  width: 100% !important;
  border-radius: 15px;
  min-height: 45px !important;
  line-height: 45px !important;
}
.quick-search-button {
  line-height: 30px !important;

  padding: 20px 20px !important;
}
.campaign-select-button {
  border-radius: 15px !important;
  font-weight: bold;
  border: none;
  background-color: #3e57fe;
  color: white;
}

.campaign-select-button:disabled {
  background-color: var(--tertiary-light-gray);
}

.order-information {
  background-color: #feb82d42;
  padding: 10px 20px;
  border-radius: 10px;
}

.btn-order-preview:hover {
  color: var(--secondary-blue);
}

.gift-div:hover {
  background-image: url(./utils/img/icons/gift.svg);
  z-index: 100;
  background-repeat: no-repeat;
  /* background-position: center left; */
  /* Updated position to center left */
}

.plus-div::before {
  background-image: url(./utils/img/icons/plus.svg);
}

.iconUserActive::before {
  background-image: url(./utils/img/icons/user-blue.svg);
}

#main-actions .dropdown-box ul li a.icon-receipt::before,
.mobile-dropdown-box ul li a.icon-receipt::before {
  background-image: url(./utils/img/icons/receipt.svg);
}

#main-actions .dropdown-box ul li a.icon-receipt:hover::before,
.mobile-dropdown-box ul li a.icon-receipt:hover::before {
  background-image: url(./utils/img/icons/receipt-blue.svg);
}

.iconReceiptActive::before {
  background-image: url(./utils/img/icons/receipt-blue.svg);
}

#main-actions .dropdown-box ul li a.icon-location::before,
.mobile-dropdown-box ul li a.icon-location::before {
  background-image: url(./utils/img/icons/location.svg);
}

#main-actions .dropdown-box ul li a.icon-location:hover::before,
.mobile-dropdown-box ul li a.icon-location:hover::before {
  background-image: url(./utils/img/icons/location-blue.svg);
}

.iconLocationActive::before {
  background-image: url(./utils/img/icons/location-blue.svg);
}

#main-actions .dropdown-box ul li a.icon-card::before,
.mobile-dropdown-box ul li a.icon-card::before {
  background-image: url(./utils/img/icons/card.svg);
}

#main-actions .dropdown-box ul li a.icon-card:hover::before,
.mobile-dropdown-box ul li a.icon-card:hover::before {
  background-image: url(./utils/img/icons/card-blue.svg);
}

.information-image {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: var(--tertiary-light-gray);
}

.load .card {
  background-image: url(./utils/img/icons/user-blue.svg);
}

.load .twt {
  background-image: url(./utils/img/icons/twitter.png);
}

.load .ig {
  background-image: url(./utils/img/icons/instagram-blue.svg);
}

.load .fb {
  background-image: url(./utils/img/icons/facebook-blue.svg);
}

.load .cancel-container {
  background-image: url(./utils/img/icons/packaging-cancel.svg);
}

.load .user {
  background-image: url(./utils/img/icons/card-blue.svg);
}

.load .receipt {
  background-image: url(./utils/img/icons/receipt-blue.svg);
}

.load .cantatemizleme {
  background-image: url(./utils/img/icons/fluent-emoji-high-contrast_handbag.svg);
  background-repeat: no-repeat;
}

.load .location {
  background-image: url(./utils/img/icons/location-blue.svg);
}

.load .logout {
  background-image: url(./utils/img/icons/power-circle-blue.svg);
}

.iconCardActive::before {
  background-image: url(./utils/img/icons/card-blue.svg);
}

#main-actions .dropdown-box ul li a.icon-power-circle::before,
.mobile-dropdown-box ul li a.icon-power-circle::before {
  background-image: url(./utils/img/icons/power-circle.svg);
}

#main-actions .dropdown-box ul li a.icon-power-circle:hover::before,
.mobile-dropdown-box ul li a.icon-power-circle:hover::before {
  background-image: url(./utils/img/icons/power-circle-blue.svg);
}

/* 02- HEADER END */
.btn-holder {
  position: relative;
}

.awaiting-confirm {
  position: absolute;
  bottom: 20px;
  right: 10px;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  padding: 8px 51px 7px 15px;
  background-color: var(--primary-orange);
  border-radius: 8px;
}
.confirming-arrow {
  margin-top: 10px;
  background-color: #fff;
  width: 23px;
  height: 23px;
  border-radius: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.awaiting-confirm-mobile-text {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.awaiting-confirm-mobile {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  max-width: 160px;
  color: #fff;
  padding: 10px 15px;
  background-color: var(--primary-orange);
  border-radius: 8px;
}
.awaiting-confirm.details {
  background-color: #e8e8e8;
  color: #676767;
  font-weight: bold !important;
}
.awaiting-confirm-mobile.details {
  background-color: #e8e8e8;
  color: #676767;
  font-weight: bold !important;
}
.awaiting-confirm.details-link {
  background-color: #e8e8e8;
  color: #676767;
  font-weight: bold !important;
  bottom: 0;
}
.awaiting-confirm-mobile.details-link {
  background-color: #e8e8e8;
  color: #676767;
  font-weight: bold !important;
  bottom: 0;
}

.awaiting-confirm::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 7px;
  right: 10px;
  background-color: #fff;
  border-radius: 6px;
  background-image: url(./utils/img/icons/long-arrow-right.png);
  background-size: 11px 7px;
  background-repeat: no-repeat;
  background-position: 4.5px 6.5px;
}

/* 03- SLIDER */
#slider {
  /* height: calc(100vh - 10px); */
  height: auto;
  /* height: 700px; */

  background-image: url(./utils/img/bg/slider-bg.png);
}
#slider .sliderShowMore {
  color: var(--primary-blue);
  text-decoration: underline;
  font-weight: 600;
  padding-left: 11px;
}
.seoDecription p {
  font-size: 14px !important;
  padding: 0 !important;
  margin: 0 !important;
}
.seoDecription p:last-child {
  margin-bottom: 10px !important;
}
#slider .slider-container {
  position: relative;
  height: 100%;
}

#slider .slide-content,
#slider .slide-image {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

#slider .slide-content {
  margin-left: 11px;
}

#slider h1 {
  min-height: 240px;
  line-height: 3.8rem;
  font-weight: 700;
  font-size: 35px;
  margin-top: 30px;
  max-width: 500px;
  margin-bottom: 240px;
}

#slider p {
  font-weight: 400;
  font-size: 18px;
  color: #989898;
}

#slider .slide-image {
  width: 100%;
  align-items: flex-end;
}

#slider .slide-image img {
  align-self: end;
}

#slider .quick-search {
  position: absolute;
  left: 11px;
  top: 276px;
  padding: 15px 25px 25px 25px;
  background-color: #fff;
  border-radius: 35px;
  border: 3.5px solid var(--tertiary-light-gray);
}

#slider .quick-search form,
#slider .quick-search .form {
  display: flex;
}

#slider .quick-search .form-group {
  display: flex;
  flex-direction: column;
  /* margin-right: 15px; */
}

#slider .quick-search .form-group label {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 11px;
  padding-left: 5px;
}

/* #slider .quick-search .form-group label:after {
  content: url(./utils/img/icons/chevron-down.svg);
  width: 14px;
  height: 8px;
  margin-left: 10px;
} */
#slider .quick-search .form-group input {
  color: var(--tertiary-dark-gray);
  width: 150px;
  border: none;
  padding-left: 5px;
}

#slider .quick-search .form-group input:focus {
  outline: 2px solid var(--primary-blue);
  border-radius: 3px;
}

#slider .quick-search button {
  border: none;
}

.product-item .slick-dots {
  bottom: -40px !important;
}
.slick-dots {
  bottom: 30px !important;
}

.slick-track {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

li.slick-active > button::before {
  /* width: 30px !important; */
  padding-bottom: 10px !important;
  height: 10px !important;
  background-color: var(--primary-orange) !important;
  /* color: var(--primary-orange) !important; */
  color: transparent !important;
  border-radius: 5px !important;
}

.slick-dots li button:before {
  height: 15px;
  line-height: 11px !important;
}

.slick-dots li {
  padding-bottom: 20px !important;
}

.slick-dots li button {
  line-height: 0 !important;
}

.slider-dots {
  position: absolute;
  width: 100%;
  bottom: 60px;
  display: flex;
  justify-content: center;
}

.slider-dots .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 20px;
  background-color: var(--tertiary-light-gray);
}

.slider-dots .dot.active {
  width: 30px;
  background-color: var(--primary-orange) !important;
  border-radius: 5px;
}

/* 03- SLIDER END */

/* 04- PROCESS */
section#process,
section.page-section {
  padding: 40px 0;
  text-align: center;
}

/* section#process h2,
section.page-section h2 {
  font-size: 36px;
  max-width: 500px;
  font-weight: 600;
  margin-bottom: 80px;
} */
.first-half h2,
.second-half h2 {
  max-width: 800px !important;
}
section#process h1,
section.page-section h1 {
  font-size: 36px;
  /* max-width: 500px; */
  font-weight: 600;
  margin-bottom: 80px;
}

section#process.page-section h2 {
  min-width: 560px;
}

section#process h4,
section.page-section h4 {
  color: #3e57fe;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}

section#process .process {
  display: flex;
  justify-content: space-between;
}

section#process .process-box {
  position: relative;
  overflow: visible;
  width: 350px;
}

section#process .process-box:not(:last-of-type)::after {
  content: "";
  position: absolute;
  display: block;
  right: -67%;
  top: 40px;
  height: 2px;
  width: 100%;
  background-color: var(--primary-blue);
}

.process-box .number {
  display: inline-block;
  z-index: 0;
  position: relative;
  background-color: #f9f9f9;
  padding: 0 20px;
  margin-bottom: 30px;
}

.process-box .number > span {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
  border: 2px solid var(--primary-blue);
  border-radius: 20px;
  transition: background-color 0.5s;
}

.process-box .number > span:hover {
  background-color: var(--primary-blue);
}

.process-box .number span span {
  position: absolute;
  font-size: 36px;
  color: var(--primary-blue);
  font-weight: 600;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: color 0.5s;
}

.process-box .number > span:hover span {
  color: #fff;
}

.process-box h5 {
  font-size: 18px;
  font-weight: 600;
  color: var(--tertiary-black);
}

.process-box p {
  max-width: 350px;
  color: var(--tertiary-dark-gray);
  font-size: 16px;
  line-height: 28px;
}

/* 04- PROCESS END */

/* 05- SERVICES */
.service-slider {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 70px;
}

.service-slider .service-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 255px;
}

.service-slider .service-box .icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 20px;
  margin-bottom: 20px;
}

.service-slider .service-box .icon.lostra {
  background-color: #dafee2;
}

.service-slider .service-box .icon.canta {
  background-color: #4842b453;
}

.service-slider .service-box .icon.iron {
  background-color: #d6201d4f;
}

.service-slider .service-box .icon.ayakkabi {
  background-color: #dafee2;
}

.service-slider .service-box .icon.kuru-temizleme {
  background-color: #fff1d5;
}

.service-slider .service-box .icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service-slider .service-box h5 {
  color: var(--tertiary-black);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.service-slider .service-box p {
  color: var(--tertiary-dark-gray);
  font-size: 16px;
  line-height: 28px;
}

.service-slider .sliding-image {
  padding: 32px 21px;
  position: relative;
}

.service-slider .sliding-image .before-after-slider {
  background-color: var(--primary-orange);
  border-radius: 30px;
  height: 399px;
  width: 403px;
}

.service-slider .sliding-image .sliding-bar {
  position: absolute;
  top: 0;
  left: 48%;
  width: 5px;
  height: 100%;
  background-color: var(--primary-blue);
  z-index: 1;
}

.service-slider .sliding-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service-action {
  text-align: center;
}

.service-content {
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.service-content p {
  font-size: 18px;
  line-height: 30px;
  color: #2e2e2e;
  margin-bottom: 30px;
}
/* 
.service-content p:last-of-type {
  margin-bottom: 60px;
} */

.service-content a {
  align-self: flex-start;
}

.service-image {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
}

.service-image img {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.price-list-box {
  display: flex;
  justify-content: space-between;
  padding: 90px 95px 60px 95px;
  border: 2px solid var(--tertiary-light-gray);
  border-radius: 25px;
  background-color: #fff;
}

.price-list-box-allPrices {
  display: flex;
  justify-content: space-between !important;
  /* padding: 90px 95px 60px 95px; */
  /* border: 2px solid var(--tertiary-light-gray); */
  border-radius: 25px;
  background-color: #fff;
}

.price-list-box ul,
.price-list-box-allPrices ul {
  padding-left: 0;
}

.price-list-box ul li,
.price-list-box-allPrices ul li {
  list-style: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.price-list-box ul li p,
.price-list-box-allPrices ul li p {
  /* font-size: 16px; */
  font-weight: 500;
  margin-right: 40px;
}

.price-list-box ul li span,
.price-list-box-allPrices ul li span {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-blue);
}

/* 05- SERVICES END */

/* 06- APP */
section#app {
  background-repeat: no-repeat, no-repeat;
  background-size: contain, cover;
  background-position: right center, center center;
  padding: 30px 0;
  height: 630px;
}

.app-content {
  padding-right: 105px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.app-content h3 {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  text-align: left;
}

.mobileAppInfo {
  background-image: url(./utils/img/app-download-image.webp),
    url(./utils/img/bg/app-bg.webp);
}

.app-content h5 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-align: left;
  margin-bottom: 36px;
}

.app-buttons a:first-of-type {
  margin-right: 15px;
}

/* 06- APP END */

.order-progress-bar {
  margin-bottom: 16px;
  height: 18px;
  position: relative;
}
.order-progress-bar-mobile {
  /* margin-bottom: 16px; */
  height: 18px;
  position: relative;
}

.order-progress-bar-mobile::before {
  content: "";
  position: absolute;
  left: 5px;
  /* top: 7px; */
  width: 40px;
  height: 250px;
  border-left: 2px dashed var(--tertiary-light-gray);
}
.order-progress-bar-mobile::after {
  content: "";
  position: absolute;
  left: 5px;
  /* top: 7px; */
  width: 40px;
  height: 100%;
  border-left: 2px solid var(--secondary-green);
}
.order-progress-bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 100%;
  height: 2px;
  border-bottom: 2px dashed var(--tertiary-light-gray);
}

.order-progress-bar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 100%;
  height: 2px;
  border-bottom: 2px solid var(--secondary-green);
}

.order-progress-bar.active::after {
  width: 10%;
}
/* .order-progress-bar-mobile::after {
  height: 10%;
} */

.order-progress-bar.pickedup::after {
  width: 28%;
}
.order-progress-bar-mobile.pickedup::after {
  height: 50px;
}

.order-progress-bar-mobile.ongoing::after {
  height: 140px;
}
.order-progress-bar-mobile.pending::after {
  height: 90px;
}
.order-progress-bar.pending::after {
  width: 40%;
}

.order-progress-bar.ongoing::after {
  width: 60%;
}

.order-progress-bar.delivering::after {
  width: 75%;
}
.order-progress-bar-mobile.delivering::after {
  height: 190px;
}

.order-progress-bar.confirming::after {
  width: 40%;
}
.order-progress-bar-mobile.confirming::after {
  height: 140px;
}

/* .order-progress-bar.ongoing.done::after {
  width: 80%;
} */
.order-progress-bar.done::after {
  width: 100%;
}
.main-checkout-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.order-progress-bar .progress-meter {
  position: absolute;
  left: 52%;
  top: 0;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: var(--secondary-green);
}
.order-progress-bar-mobile .progress-meter-mobile {
  position: absolute;
  left: -3px;
  top: 0;
  z-index: 1000;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: var(--secondary-green);
}
.header-icons {
  width: 32px;
  height: 32px;
}

.order-progress-bar.active .progress-meter {
  left: 10%;
}
/* .order-progress-bar-mobile.active .progress-meter-mobile {
  top: 40%;
} */

.mainImagesOrderSlider {
  width: 100%;
  object-fit: contain;
}
.order-progress-bar.pickedup .progress-meter {
  left: 28%;
}
.order-progress-bar-mobile.pickedup .progress-meter-mobile {
  top: 50px;
}

.order-progress-bar-mobile.pending .progress-meter-mobile {
  top: 90px;
}
.order-progress-bar-mobile.ongoing .progress-meter-mobile {
  top: 140px;
}
.order-progress-bar.pending .progress-meter {
  left: 40%;
}

.order-progress-bar.ongoing .progress-meter {
  left: 60%;
}

.order-progress-bar.delivering .progress-meter {
  left: 75%;
}
.order-progress-bar-mobile.delivering .progress-meter-mobile {
  top: 180px;
}

.order-progress-bar.confirmed .progress-meter {
  left: 72%;
}

.order-progress-bar.confirming .progress-meter {
  left: 40%;
}
.order-progress-bar-mobile.confirming .progress-meter-mobile {
  top: 140px;
}

/* .order-progress-bar.in-progress.done .progress-meter {
  left: 80%;
} */
.order-progress-bar.done .progress-meter {
  left: 100%;
}

.order-progress-bar .progress-meter::after {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  top: 48%;
  left: 52%;
  transform: translate(-50%, -50%);
  background-image: url(./utils/img/icons/arrow-right-white.svg);
  background-size: 11px 11px;
}
.order-progress-bar-mobile .progress-meter-mobile::after {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  top: 55%;
  left: 58%;
  transform: translate(-50%, -50%);

  background-image: url(./utils/img/icons/arrow-down.svg);
  background-size: 10px 10px;
  background-repeat: no-repeat;
}

/* 07- PRIVILEGES */
.privileges {
  margin-top: 60px;
}
.left h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}
.mob {
  display: none;
}
.privilege-box {
  padding: 40px;
  border: 2px solid var(--tertiary-light-gray);
  border-radius: 20px;
  margin-bottom: 36px;
  /* min-width: 290px; */
  height: 330px;
  background-color: #fff;
  transition: border-color 0.5s;
}

.comment-box {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  border: 2px solid var(--tertiary-light-gray);
  border-radius: 20px;
  margin-bottom: 36px;
  /* min-width: 290px; */
  /* height: 600px; */
  background-color: #fff;
  transition: border-color 0.5s;
}

.privilege-box.blue:hover {
  border-color: #3e57fe;
}

.privilege-box.yellow:hover {
  border-color: #feb72d;
}

.privilege-box.green:hover {
  border-color: #46f86d;
}

.privilege-box.red:hover {
  border-color: #d6201d;
}

.privilege-box.purple:hover {
  border-color: #4842b4;
}
.product-details-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

section.big-title .privilege-box h3 {
  font-size: 18px;
  text-transform: none;
  font-weight: 600;
  color: var(--tertiary-black);
  margin-top: 18px;
}

.privilege-box p {
  font-size: 16px;
  line-height: 28px;
  color: var(--tertiary-dark-gray);
}

/* 07- PRIVILEGES END */
/* CAMPAIGNS */
.brand-logos {
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 50px;
}

.brand-logo {
  width: 15%;
  /* Adjust the width based on your desired layout */
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-logo img {
  /* width: 100%; */
  min-width: 90px;
  object-fit: contain;
  min-height: 80px;
}

/* 08- LOCATIONS */
.hizmet-bolgeleri {
  background-image: url(./utils/img/bg/location-bg.png);
}

.locations {
  padding: 55px 0;
}

.location-box {
  padding: 35px 40px;
  background-color: #fff;
  border-radius: 25px;
  border: 2px solid #fff;
  margin-bottom: 30px;
}

.location-box:hover {
  border-color: #feb72d;
}

section.big-title .location-box h3 {
  text-transform: none;
  margin-bottom: 6px;
}

section.big-title .location-box p {
  font-size: 16px;
  line-height: 28px;
  color: #000;
}

/* 08- LOCATIONS END */

/* 09- COMMENTS */
.comments {
  display: flex;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 60px;
}

.comment-box {
  /* max-width: 445px; */
  width: 95% !important;
  min-height: 450px;
  cursor: grab;
  padding: 55px 30px 40px 30px;
  margin: 0 30px;
  transition: 0.3s ease all;
  border: 2px solid transparent;
}

.comment-box:hover {
  border: 2px solid var(--primary-orange);
}

.comment-box:active {
  cursor: grabbing;
}

.comment-box .stars {
  text-align: center;
  margin-bottom: 35px;
}
.carousel-image {
  width: 120px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
.comment-box .stars .star {
  display: inline-block;
  width: 21px;
  height: 20px;
  background-repeat: no-repeat;
}

.comment-box .stars .star:not(:last-of-type) {
  margin-right: 10px;
}

.comment-box .stars .star.star-full {
  background-image: url(./utils/img/icons/star-full.png);
}

.comment-box .stars .star.star-half {
  background-image: url(./utils/img/icons/star-half.png);
}

.comment-box .stars .star.star-empty {
  background-image: url(./utils/img/icons/star-empty.png);
}

.comment-box .comment p {
  color: #151517;
  font-size: 18px;
}

.comment-box .comment h5 {
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 5px;
}

.comment-navigation {
  display: flex;
  justify-content: center;
}
.carousel-navigation {
  display: flex;
}

.comment-navigation .arrow,
.carousel-navigation .arrow {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: transparent;
  position: relative;
  transition: background-color 0.5s;
}

.comment-navigation .arrow:first-of-type,
.carousel-navigation .arrow:first-of-type {
  margin-right: 10px;
}

.comment-navigation .arrow:hover,
.carousel-navigation .arrow:hover {
  background-color: var(--primary-blue);
}

.comment-navigation .arrow:before,
.carousel-navigation .arrow:before {
  width: 22px;
  height: 14px;
  position: absolute;
  left: 50%;
  top: 44%;
  transform: translate(-50%, -50%);
}
.comment-navigation .arrow:before,
.carousel-navigation .arrow:before {
  width: 22px;
  height: 14px;
  position: absolute;
  left: 50%;
  top: 44%;
  transform: translate(-50%, -50%);
}

.comment-navigation .arrow.prev:before {
  content: url(./utils/img/icons/long-arrow-left.png);
}
.carousel-navigation .arrow.prev:before {
  content: url(./utils/img/slider/direction-left1.svg);
}
.comment-navigation .arrow.next:before {
  content: url(./utils/img/icons/long-arrow-right.png);
}
.carousel-navigation .arrow.next:before {
  content: url(./utils/img/slider/direction-left2.svg);
}

.comment-navigation .arrow.prev:hover:before {
  content: url(./utils/img/icons/long-arrow-left-white.png);
}

.comment-navigation .arrow.next:hover:before {
  content: url(./utils/img/icons/long-arrow-right-white.png);
}

/* 09- COMMENTS END */

/* 10- FAQ */
.faq {
  margin-top: 100px;
  margin-bottom: 56px;
}

.sss-slider {
  margin-top: 100px;
  margin-bottom: 56px;
}

.faq-box {
  text-align: left;
  border: 2px solid var(--tertiary-light-gray);
  padding: 30px 110px 40px 40px;
  border-radius: 25px;
  position: relative;

  margin-bottom: 24px;
  height: 90px;
  overflow: hidden;
  transition: all 0.7s;
  background-color: #fff;
}

.faq-box h3 {
  font-size: 16px;
  font-weight: 600;
  /* line-height: 26px; */
  color: #2e2e2e;
  margin-bottom: 30px;
}

.faq-box p,
.faq-box .price-list-box-allPrices {
  font-size: 16px;
  /* line-height: 28px; */
  color: var(--tertiary-dark-gray);
  margin-bottom: 0;
  margin-top: 40px;
  max-height: 0;

  transition: max-height 0.7s;
}

.faq-box.active {
  overflow: visible;
  height: auto !important;
  padding-bottom: 30px;
}

.faq-box.active h3 {
  margin-bottom: 16px;
}

.faq-box.active p,
.faq-box.active .price-list-box-allPrices {
  max-height: none;
}

.faq-box:not(.active) {
  cursor: pointer;
}

.faq-box .activate {
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 40px;
  width: 32px;
  height: 32px;
}

.faq-box .activate:before {
  content: url(./utils/img/icons/chevron-down-blue.png);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17px;
  cursor: pointer;
  height: 17px;
  transform: translate(-50%, -50%);
}

.faq-box.active .activate:before {
  content: url(./utils/img/icons/times-gray.png);
}

/* 10- FAQ END */

/* 11- FOOTER */
footer {
  background-color: #2e2e2e;
  color: #fff;
  padding-top: 70px;
}

footer .footer-logo {
  margin-bottom: 15px;
}

footer p {
  font-size: 14px;
  line-height: 24px;
  color: var(--tertiary-light-gray);
  max-width: 400px;
}
footer .footer-main-information {
  max-width: 600px;
  text-align: center;
}

footer .footer-whatsapp {
  display: flex;
}

footer .footer-whatsapp .icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 13px;
  position: relative;
  margin-right: 16px;
}

footer .footer-whatsapp .icon img {
  position: absolute;
  left: 50%;
  width: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
}

footer .footer-whatsapp p {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 9.33px;
}

footer .footer-whatsapp a {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

footer .footer-whatsapp a:hover {
  color: var(--primary-blue);
}

footer h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-menu {
  list-style: none;
  padding-left: 0;
}

.footer-menu li {
  margin-bottom: 14px;
}

.footer-menu li a {
  color: var(--tertiary-light-gray);
  font-size: 12px;
}
.footer-menu.upper-footer-inner a {
  color: black;
  font-size: 12px;
}
.upper-footer-inner li {
  font-size: 12px;
}

.footer-menu li a:hover {
  color: var(--primary-blue);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 48px 0 30px;
}

.footer-bottom .footer-social {
  list-style: none;
  padding-left: 0;
}

.footer-bottom .footer-social li {
  display: inline;
  margin-right: 30px;
}

.footer-bottom .footer-social li:last-of-type {
  margin-right: 0;
}

/* 11- FOOTER END */
/* 12 Policy*/
.policy h4 {
  color: black !important;
}

/* @media (min-width: 1367px) {
  #slider {
    height: auto;
  }
} */

/* 99- RESPONSIVE */
@media only screen and (max-width: 1400px) {
  .location-box {
    height: 200px;
  }
  header #main-actions .button:first-of-type {
    padding: 0 15px !important;
  }
}
@media only screen and (max-width: 1367px) {
  /* 01- MAIN */
  .service-selection {
    flex-wrap: wrap;
  }

  form label,
  .form label {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .birthdayLabel {
    font-size: 16px !important;
    margin-bottom: 10px;
  }

  form input,
  .form input {
    padding: 12px 20px;
  }

  /* 01- MAIN END */

  /* 13- REGISTER */
  /* .register-content {
    min-width: 65%;
    margin: 0 25px;
  } */

  .register-content h1 {
    font-size: 30px;
    margin-bottom: 8px;
  }

  .register-content p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  /* 13- REGISTER END */

  /* 14- ORDER */
  .order-box {
    padding: 30px;
  }

  .order-box p,
  .order-box table tr td {
    font-size: 14px;
  }

  .order-box table tr td:first-of-type {
    padding-right: 10px;
  }

  .order-box ul li {
    font-size: 14px;
    margin-bottom: 15px;
  }

  section.page-section .order-box h5 {
    margin-bottom: 10px;
  }

  /* 14- ORDER END */
}

@media only screen and (min-width: 1400px) {
  .play-button {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .ecommerce-products {
    grid-template-columns: repeat(3, 1fr);
  }
  #slider .quick-search #slider .slider-dots {
    position: static;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  header #main-actions .button:first-of-type {
    padding: 0 15px !important;
  }
  .carousel-image {
    width: 110px;
    height: 80px;
    background-color: #fff;
    border-radius: 10px;
    padding: 9px;
  }
  .footer-main {
    flex-direction: column;
  }
  .product-details-container {
    grid-template-columns: repeat(1, 1fr);
  }
  header nav ul > li a,
  header #main-actions .dropdown-box ul li a,
  .mobile-dropdown-box ul li a {
    font-size: 10px;
  }
  header nav ul > li {
    margin-right: 20px;
  }
  .campaign-label {
    font-size: 12px !important;
  }
  #slider .container {
    max-width: 720px;
  }
  #slider .quick-search #slider .slider-dots {
    position: static;
    margin-top: 60px;
  }
  #slider .quick-search {
    position: static;
    margin-top: 60px;
  }

  #slider h1 {
    font-size: 37px;
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 1280px) {
  /* 06- APP */
  /* #slider .quick-search {
    top: 270px;
  } */
  .ecommerce-products {
    grid-template-columns: repeat(3, 1fr);
  }
  #slider h1 {
    font-size: 39px;
    /* margin-bottom: 0 !important; */
  }

  .delete-button {
    /* top: 20px; */
    bottom: 10px;
  }

  .app-content {
    padding-right: 70px;
  }

  .app-content h3 {
    font-size: 44px;
  }

  /* 06- APP END */
}

@media only screen and (max-width: 1024px) {
  .ecommerce-products {
    grid-template-columns: repeat(2, 1fr);
  }

  .e-commerce-container {
    flex-direction: column;
  }
  .header-icons {
    width: 22px;
    height: 22px;
  }
  .counter-holder {
    font-size: 10px !important;
    width: 16px !important;
    height: 16px !important;
    top: 2px !important;
    right: 5px !important;
    border: 1.5px solid var(--primary-blue) !important;
  }
  .mob {
    display: flex;
    width: 36px;
    height: 36px;
  }
  /* 02- HEADER */
  /* .comment-box .comment p {
    font-size: 12px;
  } */
  .carousel-image {
    width: 100px;
    height: 60px;
    background-color: #fff;
    border-radius: 10px;
    padding: 6px;
  }

  header #main-nav {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 110px;
    min-width: 300px;
    overflow: hidden;
    opacity: 0;
    padding: 29px 25px;
    background-color: #fff;
    border-radius: 24px;
    -webkit-box-shadow: 0px -1px 13px 4px rgb(0 0 0 / 5%);
    box-shadow: 0px -1px 13px 4px rgb(0 0 0 / 5%);
    transition: opacity 0.5s, visibility 0.5s;
  }
  header .header-main {
    height: 100px;
  }

  /* header nav, */
  header #main-actions {
    display: none;
  }
  header .mobile-menu {
    display: block;
  }
  .mobile-profile {
    display: block !important;
  }

  header nav ul {
    padding-left: 0;
  }

  header nav ul > li {
    display: block;
    margin-right: 0;
    margin-bottom: 15px;
  }

  header nav ul > li a {
    padding: 5px 10px;
  }

  header nav ul > li.dropdown .dropdown-box {
    /* display: none; */
    position: static;
    padding-top: 0;
    width: auto;
    /* height: 0; */
    overflow: hidden;
    transition: 0.5s ease height;
  }

  header nav ul > li.dropdown:hover .dropdown-box {
    display: block;
  }

  header nav ul > li.dropdown .dropdown-box .inner {
    padding: 10px 8px;
    background-color: transparent;
    border-radius: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  header nav ul > li.dropdown .dropdown-box ul li {
    margin-bottom: 15px;
  }

  header nav ul > li.dropdown .dropdown-box.mega {
    width: 320px;
  }

  header nav ul > li.dropdown .dropdown-box.mega .inner {
    padding: 20px 16px;
  }

  header nav ul > li.dropdown .dropdown-box.mega ul {
    display: none;
    height: 0;
    overflow: hidden;
    transition: 0.5s ease height;
  }

  header nav ul > li.dropdown .dropdown-box.mega ul.active-price-ul {
    display: block;
    height: 100%;
    /* overflow: hidden; */
    transition: 0.5s ease height;
  }

  header nav ul > li.dropdown .dropdown-box.mega ul li {
    font-size: 16px;
  }

  header nav ul > li.dropdown .dropdown-box.mega h3 {
    cursor: pointer;
    margin-bottom: 15px !important;
    padding: 5px 10px;
  }

  .header-main {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .mobile-nav {
    display: flex !important;
  }
  .confirmation-page {
    flex-direction: column;
  }
  #slider h1 {
    font-size: 39px;
  }

  .location-box p {
    font-size: 14px !important;
  }

  .location-box h3 {
    font-size: 20px !important;
  }

  .faq-box {
    height: 90px;
  }

  .faq-box h3 {
    font-size: 14px !important;
  }

  .blog-container {
    display: flex;
    justify-content: center !important;
  }

  .quick-search-button {
    /* line-height: 20px !important; */
    font-size: 14px !important;

    /* padding: 10px 20px !important; */
    min-width: 170px !important;
  }

  header nav ul > li {
    margin-right: 20px;
  }

  .order-box {
    margin-top: 20px;
  }

  /* 02- HEADER END */

  /* 03- SLIDER */

  #slider {
    height: auto;
    padding-bottom: 60px;
  }

  #slider .quick-search,
  #slider .slider-dots {
    position: static;
    margin-top: 60px;
  }

  #slider .quick-search form,
  #slider .quick-search .form {
    width: 100%;
    justify-content: space-between;
  }

  /* 03- SLIDER END */
  #slider h1 {
    margin-bottom: 29px;
  }

  #slider .slide-image {
    align-items: center;
    /* width: 80%; */
  }

  #slider .slide-image img {
    align-self: start;
  }

  /* 04- PROCESS */
  /* .process-box p {
    width: 250px;
  } */

  /* 04- PROCESS END */

  /* 05- SERVICES */
  .service-slider .sliding-image .before-after-slider {
    height: 350px;
    width: 365px;
  }

  .price-list-box,
  .price-list-box-allPrices {
    padding: 60px 65px 60px 65px;
  }

  .price-list-box ul li p,
  .price-list-box-allPrices ul li p {
    margin-right: 20px;
  }

  /* 05- SERVICES END */

  /* 06- APP */
  section#app {
    height: 488px;
  }

  .app-content {
    padding-right: 40px;
  }

  .app-content h3 {
    font-size: 38px;
  }

  .app-image img {
    max-width: 470px;
  }

  /* 06- APP END */
}

@media only screen and (max-width: 992px) {
  .footer-menu.borderLeft {
    border-left: none !important;
    padding-left: 0 !important;
  }
  .pd-20 {
    padding: 0 !important;
  }
  .favorites {
    width: 100%;
  }
  .product-counter-container.checkout {
    width: 80px !important;
    padding: 5px 5px !important;
    justify-content: space-around !important;
  }
  .remove-cart-icon {
    width: 10px;
  }
  .product-counter-container span {
    font-size: 12px !important;
  }
  .checkout-total-table-price {
    font-size: 12px !important;
  }
  .favorites-table {
    width: 100%;
    border-collapse: collapse;
  }
  .favorites-table th,
  .favorites-table td {
    border: none;
    padding: 10px;
    text-align: left;
  }
  .favorites-table td.actions {
    display: flex;
    border-radius: 1px !important;
    margin-right: 14px;
    justify-content: space-around;
  }
  .favorites-table td.actions button {
    margin-top: 10px;
    padding: 0px 10px;
    font-size: 10px;
    line-height: 20px;
    min-height: 30px;
    border-radius: 9px;
  }
  .favorites .cart-product img {
    width: 50px;
    height: 50px;
  }
  .favorites-table thead {
    border: 1px solid var(--Gray-100, #e4e7e9);
    background: var(--Gray-50, #f2f4f5);
    height: 35px !important;
  }
  .favorites-table th {
    color: var(--Gray-700, #475156);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    padding: 0 10px !important;
    font-weight: 500;
    text-transform: uppercase;
  }
  .favorites-table td.actions {
    white-space: nowrap;
  }

  .favorites .favorites-header p {
    color: var(--Gray-900, #191c1f);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 24px;
  }
  .favorites .cart-product p {
    font-size: 12px;
  }
  .stock-info {
    font-size: 10px;
  }
  .upper-footer-border {
    border: none;
  }
  .upper-footer-inner {
    text-align: center;
  }
  .ad-banner,
  .courier-banner {
    font-size: 12px;
  }
  .location-box {
    height: 260px;
  }
  section#process .process-box:not(:last-of-type)::after {
    right: -75%;
  }
}

@media only screen and (max-width: 820px) {
  /* 01- MAIN */
  .main-checkout-container {
    flex-direction: column-reverse;
  }
  .checkout-container {
    font-size: 12px;
    width: 100%;
    max-width: 290px !important;
    min-width: 240px !important;
  }
  .complate-order-button {
    padding: 0 12px !important;
    font-size: 12px !important;
  }
  .ecommerce-products {
    grid-template-columns: repeat(2, 1fr);
  }
  .header-main {
    flex-direction: row-reverse;
  }
  .mobile-nav {
    display: flex !important;
  }
  .register-right {
    align-items: flex-start !important;
  }
  .service-slider {
    align-items: center;
  }

  .play-button {
    font-size: 14px;
    padding: 10px 10px;
  }

  .price-box p,
  .price-box .price-list-box-allPrices {
    padding: 0;
    margin-top: 10px !important;
  }

  .price-list-box,
  .price-list-box-allPrices {
    padding: 60px 10px;
    font-size: 12px !important;
  }

  .price-box p,
  .price-box .price-list-box-allPrices ul li p {
    font-size: 12px !important;
  }

  .price-box p,
  .price-box .price-list-box-allPrices ul li p {
    font-size: 10px !important;
  }

  .price-box p,
  .price-box .price-list-box-allPrices {
    line-height: 10px;
  }

  .service-slider {
    flex-direction: column;
  }

  .brand-info-container {
    flex-direction: column;
  }

  .brand-img-container {
    margin: 0 auto;
  }

  .service-selection {
    flex-wrap: wrap;
  }

  .search-bar form input,
  .search-bar .form input {
    width: 450px;
  }

  .awaiting-confirm {
    bottom: 50px;
    top: 60px;
    width: 180px;
    height: fit-content;
  }
  .awaiting-confirm.details {
    bottom: 0;

    top: 120px;
    width: 200px;
  }
  /* .awaiting-confirm.details-link {
    bottom: 20px;
    top: 20px;
    width: 200px;
  } */
  /* 01- MAIN END */
  /* register  */
  .register-content {
    min-width: 60%;
  }

  .register-content.max {
    max-width: 60%;
  }

  /* 02- HEADER */
  header .header-main {
    height: 100px;
  }

  /* header nav, */
  header #main-actions {
    display: none;
  }
  header .mobile-menu {
    display: block;
  }
  .mobile-profile {
    display: block !important;
  }

  header nav ul {
    padding-left: 0;
  }

  header nav ul > li {
    display: block;
    margin-right: 0;
    margin-bottom: 15px;
  }

  header nav ul > li a {
    padding: 5px 10px;
  }

  header nav ul > li.dropdown .dropdown-box {
    /* display: none; */
    position: static;
    padding-top: 0;
    width: auto;
    /* height: 0; */
    overflow: hidden;
    transition: 0.5s ease height;
  }

  header nav ul > li.dropdown:hover .dropdown-box {
    display: block;
  }

  header nav ul > li.dropdown .dropdown-box .inner {
    padding: 10px 8px;
    background-color: transparent;
    border-radius: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  header nav ul > li.dropdown .dropdown-box ul li {
    margin-bottom: 15px;
  }

  header nav ul > li.dropdown .dropdown-box.mega {
    width: 320px;
  }

  header nav ul > li.dropdown .dropdown-box.mega .inner {
    padding: 20px 16px;
  }

  header nav ul > li.dropdown .dropdown-box.mega ul {
    display: none;
    height: 0;
    overflow: hidden;
    transition: 0.5s ease height;
  }

  header nav ul > li.dropdown .dropdown-box.mega ul.active-price-ul {
    display: block;
    height: 100%;
    /* overflow: hidden; */
    transition: 0.5s ease height;
  }

  header nav ul > li.dropdown .dropdown-box.mega ul li {
    font-size: 16px;
  }

  header nav ul > li.dropdown .dropdown-box.mega h3 {
    cursor: pointer;
    margin-bottom: 15px !important;
    padding: 5px 10px;
  }

  header nav ul > li.dropdown .dropdown-box.mega h3.has-drop-inner:after {
    content: url(./utils/img/icons/chevron-down.svg);
    width: 14px;
    height: 8px;
    margin-left: 5px;
  }

  /* 02- HEADER END */

  /* 03- SLIDER */

  #slider h1 {
    margin-bottom: 29px;
  }

  /* #slider .slide-image {
    align-items: center;
    width: 70%;
  } */

  /* #slider .slide-image img {
    align-self: start;
    width: 100%;
  } */

  /* 03- SLIDER END */

  /* 04- PROCESS */
  section#process .process {
    flex-direction: column;
  }

  .process-box {
    width: 100% !important;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .process-box .number {
    margin-bottom: 60px;
    padding: 12px 0;
    margin-right: 40px;
  }

  .process-box .content {
    text-align: left;
    padding-top: 12px;
  }

  section#process .process-box:not(:last-of-type)::after {
    width: 2px;
    height: 100px;
    top: auto;
    bottom: -31px;
    left: 153px;
  }

  .process-box h5 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .process-box p {
    width: auto;
    font-size: 18px;
  }

  /* 04- PROCESS END */

  /* 05- SERVICES */
  .service-slider {
    flex-wrap: wrap;
  }

  .service-slider .service-box {
    margin-bottom: 40px;
  }

  .service-slider .sliding-image {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: center;
  }

  .service-slider .sliding-image .before-after-slider {
    height: 399px;
    width: 403px;
  }

  .price-list-box,
  .price-list-box-allPrices {
    flex-direction: column;
    align-items: center;
  }

  /* 05- SERVICES END */

  /* 06- APP */
  section#app {
    display: block;
    height: 360px;
  }

  .app-content {
    padding-right: 0;
    align-items: flex-start;
    padding-left: 60px;
  }

  .app-content h3,
  .app-content h5 {
    text-shadow: 2px 2px 2px #000000;
  }

  .app-image {
    text-align: right;
  }

  /* 06- APP END */

  /* 09- COMMENTS */
  .comment-box.last {
    display: none;
  }

  /* 09- COMMENTS END */
  /* sss */

  /* 11- FOOTER */
  footer p {
    text-align: center;
  }

  .footer-main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* margin-bottom: 20px; */
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    /* margin: 60px 0; */
    border-bottom: 1px solid #676767;
  }

  .footer-bottom {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .footer-bottom img {
    margin-bottom: 30px;
  }

  /* 11- FOOTER END */
}

/* FAQ */

/* 14- ORDER */
.service-selection {
  margin-top: 32px;
  /* margin-bottom: 48px; */
  display: flex;
  flex-wrap: wrap;
}

.service-selection label {
  position: relative;
  margin-right: 20px;
}

.service-selection label .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 130px;
  padding: 18px 20px 54px 20px;
  background: #f9f9f9;
  cursor: pointer;
  border: none;
  border-radius: 15px;
}

.service-selection-main label .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50px !important;
  padding: 5px 40px !important;
  padding-top: 10px !important;
  background: #f9f9f9;
  cursor: pointer;
  border: 2px solid var(--tertiary-light-gray) !important;
  border-radius: 20px !important;
}
.services-icon .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100px !important;
  min-height: 107px;
  padding: 5px 40px !important;
  border: none;
  /* padding-top: 10px !important; */
  background: var(--primary-blue);
  cursor: pointer;
  border-radius: 10px !important;
}

.product-extra-details-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.product-extra-details {
  color: var(--Gray-600, #5f6c72);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
  line-height: 20px;
}
#stock-decleration {
  color: #26b43d;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
#no-stock {
  color: var(--secondary-red);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.product-extra-details span {
  color: var(--Gray-900, #191c1f);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.complate-order-button {
  display: flex;
  width: 100%;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 15px;
  background: var(--Primary, #3e57fe);
  color: var(--White, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 342.857% */
  letter-spacing: 0.168px;
  text-transform: uppercase;
}
.button-arrow {
  width: 20px;
  height: 20px;
}

.go-to-cart-button {
  display: flex;
  width: 328px;
  padding: 0px 24px;
  justify-content: center;
  color: var(--Primary, #3e57fe);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 342.857% */
  letter-spacing: 0.168px;
  text-transform: uppercase;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border-radius: 15px;
  border: 2px solid var(--Primary, #3e57fe);
}
.cart-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}
.add-cart-button {
  display: flex;
  max-width: 266px;
  width: 100%;
  color: var(--Gray-00, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.192px;
  text-transform: uppercase;
  padding: 0px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 15px;
  background: var(--Primary, #3e57fe);
}
.add-cart-button {
  transition: background-color 0.3s ease, color 0.3s ease;
}
.add-cart-button:disabled {
  background-color: var(--Gray-100, #e4e7e9);
}

.add-cart-button.added {
  background-color: var(--secondary-green);
  color: white;
}

.add-cart-button.added img {
  filter: brightness(0) invert(1);
}

.buy-now-button {
  display: flex;
  padding: 0px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 2px solid var(--Primary, #3e57fe);
  color: var(--Primary, #3e57fe);
  font-family: Montserrat;
  background-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.192px;
  text-transform: uppercase;
}
.product-counter-container {
  display: flex;
  width: 115px;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  border: 2px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
}
.counter-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary-blue);
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  color: var(--Primary, #3e57fe);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  top: 7px;
  right: 7px;
}
.checkout-container {
  display: flex;
  width: 348px;
  max-width: 348px;
  min-width: 348px;
  text-align: left;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
}
.checkout-data-container-total span:nth-child(1) {
  color: var(--Gray-900, #191c1f);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.checkout-data-container-total span:nth-child(2) {
  color: var(--Gray-900, #191c1f);
  text-align: right;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.checkout-data-container,
.checkout-data-container-total {
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.checkout-data-container span:nth-child(1) {
  color: var(--Gray-600, #5f6c72);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.checkout-data-container span:nth-child(2) {
  color: var(--Gray-900, #191c1f);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-counter-container.checkout {
  display: flex;
  width: 112px;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
}
.checkout-total-table-price {
  color: #191c1f;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-counter-container span {
  color: var(--Gray-700, #475156);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  line-height: 24px;
}
.product-price-details {
  color: var(--Primary, #3e57fe);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
.product-details-header {
  color: var(--Gray-900, #191c1f);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  max-width: 571px;
}
.services-icon .card p {
  font-size: 12px;
  padding: 0 !important;
  margin: 5px 0;
  color: white;
}

.service-selection label .card .card-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  margin-bottom: 0;
}

.service-selection label .card h2 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--tertiary-black);
}
.slider-service label .card h2 {
  color: var(--tertiary-dark-gray);
  font-weight: 500;
}

.service-selection label .card span {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  margin-bottom: 14px;
}
.services-icon .card span {
  width: 35px;
  height: 35px;
  display: inline-block;
  position: relative;
  /* margin-bottom: 14px; */
}
.service-selection label .card span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.services-icon .card span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services-icon .card span.ayakkabi::before {
  background-image: url(./utils/img/icons/shoes-white.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}

.service-selection label .card span.ayakkabi::before {
  background-image: url(./utils/img/icons/shoes-blue.svg);
}
.slider-service label .card span.ayakkabi::before {
  background-image: url(./utils/img/icons/shoes-blue.svg);
  background-size: 30px;
  background-repeat: no-repeat;
}

.services-icon .card span.kurutemizleme::before {
  background-image: url(./utils/img/icons/hanger-white.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}
.service-selection label .card span.kuru-temizleme::before {
  background-image: url(./utils/img/icons/hanger-blue.svg);
}

.service-selection label .card span.kurutemizleme::before {
  background-image: url(./utils/img/icons/hanger-blue.svg);
}

.service-selection label .card span.camasir-yikama::before {
  background-image: url(./utils/img/icons/washing-machine-blue.svg);
}

.service-selection label .card span.camasiryikama::before {
  background-image: url(./utils/img/icons/washing-machine-blue.svg);
}
.services-icon .card span.camasiryikama::before {
  background-image: url(./utils/img/icons/washing-machine-white.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}
.service-selection label .card span.utuleme::before {
  background-image: url(./utils/img/icons/iron-blue.svg);
}
.services-icon .card span.utuleme::before {
  background-image: url(./utils/img/icons/iron-white.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}
.service-selection label .card span.utulemehizmeti::before {
  background-image: url(./utils/img/icons/iron-blue.svg);
}

.service-selection label input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid var(--tertiary-light-gray);
  position: absolute;
  left: 40%;
  bottom: 16px;
  display: grid;
  place-content: center;
}

.service-selection label input[type="checkbox"]:checked::before {
  content: url(./utils/img/icons/checkmark-blue.svg);
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.service-selection label input[type="checkbox"]:checked + .card {
  background-color: var(--primary-blue);
}
.service-selection label input[type="checkbox"]:checked + .card {
  background-color: var(--primary-blue);
}

.service-selection input[type="checkbox"]:checked + .card h2 {
  color: #fff;
}
.slider-service input[type="checkbox"]:checked + .card h2 {
  color: #fff;
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.lostra::before {
  background-image: url(./utils/img/icons/shoes-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.ayakkabi::before {
  background-image: url(./utils/img/icons/shoes-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.canta-temizleme::before {
  background-image: url(./utils/img/icons/cantatemizleme.svg);
  background-repeat: no-repeat;
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.cantatemizleme::before {
  background-image: url(./utils/img/icons/cantatemizleme.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.kurutemizleme::before {
  background-image: url(./utils/img/icons/hanger-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.kuru-temizleme::before {
  background-image: url(./utils/img/icons/hanger-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.camasiryikama::before {
  background-image: url(./utils/img/icons/washing-machine-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.camasir-yikama::before {
  background-image: url(./utils/img/icons/washing-machine-white.svg);
}

.load .ayakkabi {
  background-image: url(./utils/img/icons/shoes-white.svg);
}

.load .utu {
  background-image: url(./utils/img/icons/iron-white.svg);
}

.load .aski {
  background-image: url(./utils/img/icons/hanger-white.svg);
}

.load .makine {
  background-image: url(./utils/img/icons/washing-machine-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.utuleme::before {
  background-image: url(./utils/img/icons/iron-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.utulemehizmeti::before {
  background-image: url(./utils/img/icons/iron-white.svg);
}

.add-inputs {
  text-align: right;
  padding: 24px 0 48px 0;
}

.added-input,
.extra-input,
.delivery {
  padding: 0;
  max-height: 0;
  transition: max-height 2s, padding 1s;
  overflow: hidden;
}

.added-input.active,
.extra-input.active,
.delivery.active {
  overflow: visible;
  max-height: none;
}

.added-input.active,
.extra-input.active {
  padding: 48px 0;
}

.order-form p {
  font-size: 14px;
  line-height: 24px;
}

.order-box table,
.order-field {
  margin-bottom: 30px;
}

.order-box p,
.order-box table tr td {
  font-size: 18px;
}

.order-box table tr td:first-of-type {
  font-weight: 600;
  padding-right: 15px;
}

.order-box ul li {
  font-size: 16px;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .upper-footer h3 {
    font-size: 30px;
  }
  .container {
    max-width: 600px !important;
  }
  .carousel-image {
    width: 80px;
    height: 50px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
  }
  .serviceImage {
    height: 500px !important;
  }
  .slider-service label .card {
    width: 150px !important;
  }

  .confirmation-page {
    margin: 0 20px;
    padding: 0 20px;
  }
  .process-box {
    justify-content: flex-start;
  }
  section#process .process-box:not(:last-of-type)::after {
    left: 40px !important;
  }
}

/* 14- ORDER END */
@media (max-width: 480px) {
  .product-main-slider-container,
  .product-main-slider-image,
  .ecommerce-slider {
    max-width: 310px;
  }

  .product-image-detail img {
    width: 250px;
    height: 250px;
  }
  .product-image-container {
    width: 250px;
    height: 250px;
  }
  .productInformationContainer p {
    font-size: 12px;
  }
  .ecommerce-products {
    grid-template-columns: repeat(1, 1fr);
    max-width: 250px;
  }

  .carousel-image {
    /* width: 60px; */
    /* height: 35px; */
    background-color: #fff;
    border-radius: 8px;
    /* padding: 4px; */

    /* height: 45px; */
    /* padding: 4px; */
    width: 69px;
  }

  .add-product {
    font-size: 10px;
  }
  .cancelled-product-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .ad-banner,
  .courier-banner {
    font-size: 9px;
  }
  #slider {
    height: fit-content;
    background-size: 240px;
    background-position-x: 0px;
    background-position-y: 400px;
  }

  .quick-search-button {
    margin-right: 21px;
    padding: 0px !important;
    word-break: keep-all;
    min-height: 45px;
    width: 270px;
  }
  #slider .quick-search {
    position: absolute;
    top: 165px;
    left: 0;
  }
  .service-title-slider {
    font-size: 10px !important;
  }
  .slider-service label .card {
    width: 130px !important;
  }
  .web {
    display: none !important;
  }
  .input-img {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .input-img.password {
    top: 12px;
  }
  .input-img.email {
    top: 18px;
  }
  .input-mobile,
  .date-select {
    background-color: white !important;
    border-radius: 12px;
    border: 1px solid rgba(232, 232, 232, 0.755);
    padding-left: 50px;
    background: #fff;
  }
  .form-control {
    background-color: white !important;
    border-radius: 12px !important;
    /* border: 3px solid var(--tertiary-pale-gray) !important; */
    /* padding-left: 50px !important; */
    background: #fff !important;
  }
  .forgot-password {
    padding: 0 !important;
  }

  .main-logo {
    width: 50px !important;
  }
  .cart-icon-container-inner {
    right: -90%;
  }
  .mobile {
    display: flex !important;
  }

  .slider-service label .card span.ayakkabi::before {
    background-size: 20px !important;
    top: 98% !important;
  }
  .slider-service label .card span.cantatemizleme::before {
    background-size: 19px !important;
    top: 63%;
  }
  .carousel-container {
    flex-direction: column;
    align-items: normal;
    height: 250px;
    padding: 45px;
  }
  .carousel-navigation {
    justify-content: center;
  }
  .text-banner {
    max-width: 180px;
  }
  .mobile-auth {
    display: block !important;
  }

  .service-selection {
    justify-content: center;
  }
  .date-select {
    padding: 10px 15px !important;
  }
  .privilege-button {
    padding: 0 58px;
    /* margin-right: 2px; */
  }
  .privilege-button:first-of-type {
    padding: 0px 80px;
  }
  #slider h1 {
    margin-bottom: 300px !important;
  }

  .slideshowContainer {
    width: 100%;
  }
  .button-cont {
    align-items: center;
    justify-content: left;
  }
  .awaiting-confirm::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 5px;
    right: 10px;
    background-color: #fff;
    border-radius: 6px;
    background-image: url(./utils/img/icons/long-arrow-right.png);
    background-size: 9px 5px;
    background-repeat: no-repeat;
    background-position: 2.5px 4.9px;
  }
}
@media only screen and (max-width: 510px) {
  .favorites-table th,
  .favorites-table td {
    border: none;
    padding: 5px;
    text-align: left;
    font-size: 10px;
  }
  .favorites-table td.actions {
    display: flex;
    border-radius: 1px !important;
    margin-right: 14px;
    justify-content: space-around;
  }
  .favorites-table td.actions button {
    margin-top: 10px;
    padding: 0px 10px;
    font-size: 10px;
    line-height: 20px;
    min-height: 30px;
    border-radius: 9px;
  }
  .favorites .cart-product img {
    width: 50px;
    height: 50px;
  }
  .favorites-table thead {
    border: 1px solid var(--Gray-100, #e4e7e9);
    background: var(--Gray-50, #f2f4f5);
    height: 35px !important;
  }
  .favorites-table th {
    color: var(--Gray-700, #475156);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    padding: 0 5px !important;
    font-weight: 500;
    text-transform: uppercase;
  }
  .favorites-table td.actions {
    margin: 0 !important;
  }

  .favorites-table td.actions img {
    margin: 0 !important;
  }
  .favorites .favorites-header p {
    color: var(--Gray-900, #191c1f);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 24px;
  }
  .favorites .cart-product p {
    font-size: 10px;
    max-width: 60px;
  }
  .stock-info {
    font-size: 10px;
  }
  .product-item {
    width: 159px;
    height: 225px;
  }
  .product-image {
    width: 100%;
    height: 110px;
  }
  .product-name {
    font-size: 10px;
    overflow: hidden;
    text-align: left;
  }
  .product-image img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .product-item .slick-dots {
    bottom: -20px !important;
  }
  .awaiting-confirm.details {
    bottom: 0;
    top: 120px;
    width: 160px;
  }
  .color-option {
    width: 12px;
    height: 12px;
  }
  .ecommerce-products {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    max-width: 250px;
  }
  .product-title {
    max-width: 140px;
    font-size: 12px !important;
  }

  .extra-product-img {
    width: 180px;
  }
  .product-price {
    font-size: 16px !important;
  }
  .add-product {
    font-size: 8px;
  }
  .product-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .otp-input {
    width: 40px !important;
    height: 40px;
    padding: 10px !important;
    border-radius: 4px;
  }

  .order-container-form label {
    font-size: 14px;
  }
}

@media only screen and (max-width: 420px) {
  /* 01- MAIN */
  .favorites .cart-product p {
    font-size: 10px;
    max-width: 40px;
  }
  .favorites .cart-product img {
    width: 40px;
    height: 40px;
  }
  .product-color-container button {
    line-height: 12px;
  }
  .cancel-button,
  .campaign-select-button,
  .invoice-label-text {
    font-size: 12px !important;
  }
  .slider-service-container {
    padding-left: 20px;
  }
  .error-title {
    font-size: 100px;
  }
  .service-selection {
    justify-content: center;
  }
  #slider .quick-search {
    position: absolute;
    top: 125px;
    left: 0;
  }
  .button-previous {
    padding: 5px 15px;
    border-radius: 12px;
  }

  .button-previous img {
    width: 20px;
  }
  .quick-search-button {
    margin-right: 0;
  }
  .order-information-title {
    font-size: 10px;
    line-height: 20px;
  }

  .confirm-modal-delete {
    flex-direction: column-reverse;
  }

  .input-create-order {
    flex-direction: column;
    align-items: end;
  }

  .information-image {
    top: 10px;
    width: 100px;
  }

  .promo-info p {
    font-size: 12px !important;
  }

  .awaiting-confirm {
    top: 55px;
  }

  .promo-input {
    font-size: 12px !important;
    padding: 20px 15px !important;
  }

  .promo-button {
    font-size: 12px !important;
    padding: 10px 15px !important;
    left: calc(100% - 80px);
    top: 11px;
  }

  .faq-box {
    height: 50px;
  }

  .faq-box h3 {
    margin-top: -5px !important;
  }

  header #main-actions .dropdown-box {
    /* padding-right: 50px; */
    margin-right: 40px !important;
    width: 20px !important;
  }

  .faq-box p,
  .faq-box .price-list-box-allPrices {
    padding: 0;
    margin-top: 10px !important;
  }

  .price-list-box,
  .price-list-box-allPrices {
    padding: 60px 10px;
    font-size: 12px !important;
  }

  .faq-box p,
  .faq-box .price-list-box-allPrices ul li p {
    font-size: 12px !important;
  }

  .faq-box p,
  .faq-box .price-list-box-allPrices ul li p {
    font-size: 10px !important;
  }

  .faq-box p,
  .faq-box .price-list-box-allPrices {
    line-height: 15px;
  }

  header #main-actions .dropdown-box .inner {
    width: 200px;
    padding: 30px 26px;
  }

  .otp-input {
    width: 40px !important;
    height: 40px;
    padding: 10px !important;
    border-radius: 4px;
  }

  .order-container-form label {
    font-size: 14px;
  }

  .forgot-password {
    padding: 0 0 !important;
  }

  .button-new {
    font-size: 10px !important;
  }

  .sss-slider {
    margin: 20px 0;
  }

  .service-selection {
    flex-wrap: wrap;
  }

  .awaiting-confirm {
    bottom: 50px;
    top: 60px;
    width: 120px;
    font-size: 6px !important;
  }
  .button-back {
    padding: 10px 24px !important;
  }
  .button {
    font-size: 12px;
    border-radius: 12px;
    /* line-height: 40px; */
  }
  .privilege-button {
    line-height: 58px !important;
    font-size: 14px !important;
  }

  .button-sss {
    line-height: 1.5;
  }

  section.big-title .title p {
    width: auto;
  }

  .search-bar form input,
  .search-bar .form input {
    width: 100%;
    margin-bottom: 30px;
  }

  /* 01- MAIN END */
  /* register  */
  /* .register-left,
  .register-right {
    padding: 15px 30px;
  } */

  .register-left img {
    width: 150px;
    margin-bottom: 30px;
  }

  .register-left h2,
  .register-content h1 {
    font-size: 26px;
  }

  .register-left p {
    font-size: 14px;
    line-height: 24px;
  }

  .register-content {
    min-width: 100%;
  }

  .register-content.max {
    max-width: 100%;
  }

  .register-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .form-area {
    margin-bottom: 20px;
  }

  /* 02- HEADER */
  /* header {
    padding-top: 40px;
    position: relative;
    z-index: 3;
  } */

  /* header .header-main {
    height: 70px;
  } */

  #menu-overlay {
    height: calc(100% - 110px);
  }

  /* header #main-actions {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: var(--primary-blue);
  } */

  header #main-actions .button {
    color: #fff;
    border-radius: 0;
    border: none;
    background: none;
    margin: 0 !important;
    border-left: 1px solid #fff;
  }

  header #main-nav {
    right: 12px;
    top: 125px;
    padding: 18px 14px;
  }

  header nav ul > li {
    font-size: 14px;
    margin-bottom: 8px;
  }

  header nav ul > li.dropdown .dropdown-box.mega h3 {
    font-size: 14px;
  }

  header nav ul > li.dropdown .dropdown-box.mega ul li {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
  }

  .button.button-menu {
    min-height: auto;
    line-height: inherit;
  }

  /* 02- HEADER END */

  /* 03- SLIDER */
  #slider h1 {
    font-size: 25px !important;
    line-height: 42px;
    /* margin-bottom: 300px !important; */
  }

  #slider .slide-image {
    width: 100%;
  }

  #slider .quick-search {
    width: 100%;
  }

  #slider .quick-search form,
  #slider .quick-search .form {
    display: block;
  }

  #slider .quick-search .form-group {
    text-align: center;
  }

  #slider .quick-search .form-group input {
    width: 100%;
    margin-bottom: 15px;
    padding: 12px 20px;
    text-align: center;
  }

  /* .quick-search-button {
    margin: 0;
  } */

  /* 03- SLIDER END */

  /* 04- PROCESS */
  section#process h2,
  section.page-section h2 {
    font-size: 20px;
  }
  .price-sum-integer {
    font-size: 15px;
  }
  section.page-section h4 {
    font-size: 16px;
  }

  .process-box h5 {
    font-size: 18px;
  }

  .process-box p {
    font-size: 16px;
  }

  /* 04- PROCESS END */

  /* 05- SERVICES */
  .service-slider {
    flex-direction: column;
  }

  .service-slider .service-box {
    margin-bottom: 30px;
  }

  .service-slider .sliding-image {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: center;
  }

  .service-slider .sliding-image .before-after-slider {
    height: 300px;
    width: 300px;
  }

  /* 05- SERVICES END */

  /* 06- APP */
  .app-content {
    padding-left: 30px;
  }

  .app-image img {
    max-width: 100%;
    height: auto;
  }

  .app-buttons a:first-of-type img {
    margin-bottom: 20px;
  }

  /* 06- APP END */

  /* 09- COMMENTS */
  /* .comment-box:not(.first) {
    display: none;
  } */
  .comment-box {
    max-width: none;
  }

  /* 09- COMMENTS END */

  /* 10- FAQ */
  .faq-box {
    padding-right: 60px;
  }

  .faq-box .activate {
    top: 15px;
    right: 15px;
  }

  /* 10- FAQ END */

  /* 11- FOOTER */
  footer h6,
  .footer-menu {
    text-align: center;
  }

  .footer-menu {
    margin-bottom: 60px;
  }

  /* 11- FOOTER END */
}

/* 14- ORDER */
.service-selection {
  margin-top: 32px;
  /* margin-bottom: 48px; */
  display: flex;
}

section.page-section h3 {
  font-size: 21px;
}

.service-selection label {
  position: relative;
  margin-right: 20px;
}

.service-selection label .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 130px;
  padding: 18px 20px 54px 20px;
  background: #f9f9f9;
  cursor: pointer;
  border: none;
  border-radius: 15px;
}
.slider-service label .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 150px;

  padding: 5px 10px !important;
  background: #f9f9f9;
  cursor: pointer;
  border: 1px solid var(--tertiary-light-gray) !important;
  border-radius: 20px;
}

section.page-section.left {
  text-align: left;
}

.service-selection label .card .card-title {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  margin-bottom: 0;
}
.slider-service label .card .card-title {
  max-width: 100px;
}

.service-selection label .card span {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  margin-bottom: 14px;
}

section.big-title {
  padding: 119px 0;
}

section.big-title h2,
section.big-title h3,
section.middle-title h2,
section.middle-title h3 {
  font-weight: 600;
  margin-bottom: 26px;
}

section.big-title h2,
section.big-title h3 {
  text-transform: uppercase;
}

section.big-title h2,
section.middle-title h2 {
  font-size: 36px;
}

section.big-title h3,
section.middle-title h3 {
  font-size: 24px;
  margin-bottom: 5px;
}

section.big-title .text-white h3 {
  color: var(--tertiary-light-gray);
}

section.big-title .title p,
section.middle-title .title p {
  font-size: 18px;
  line-height: 30px;
  color: var(--tertiary-dark-gray);
  /* width: 540px; */
}

section.middle-title {
  padding: 80px 0;
}

section.middle-title h2,
section.middle-title h3 {
  text-transform: none;
}

section.middle-title .title p {
  max-width: 450px;
}

section .title {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section.left .title {
  text-align: left;
  align-items: flex-start;
}

.light-bg {
  background-color: #f9f9f9;
}

.primary-bg {
  background-color: var(--primary-blue);
}

.service-selection label .card span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service-selection label .card span.lostra::before {
  background-image: url(./utils/img/icons/shoes-blue.svg);
  top: 55%;
}

.service-selection label .card span.ayakkabi::before {
  background-image: url(./utils/img/icons/shoes-blue.svg);
  top: 60%;
}
.slider-service label .card span.ayakkabi::before {
  background-image: url(./utils/img/icons/shoes-blue.svg);
  top: 85%;
}

.service-selection-main label .card span.ayakkabi::before {
  background-image: url(./utils/img/icons/shoes-blue.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}

.slider-label {
  position: relative;
}

.slider-label .plus-sign {
  position: absolute;
  top: -8px;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;

  justify-content: center;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.223);
  /* align-items: center; */
  cursor: pointer;
}

.plus-sign.active {
  color: var(--primary-blue) !important;
  font-size: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-selection label .card span.cantatemizleme::before {
  background-image: url(./utils/img/icons/fluent-emoji-high-contrast_handbag.svg);
}
.slider-service label .card span.cantatemizleme::before {
  background-image: url(./utils/img/icons/fluent-emoji-high-contrast_handbag.svg);
}

.services-icon .card span.cantatemizleme::before {
  background-image: url(./utils/img/icons/cantatemizleme.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}
.slider-service .card span.cantatemizleme::before {
  background-image: url(./utils/img/icons/cantatemizleme.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}
.service-selection-main label .card span.cantatemizleme::before {
  background-image: url(./utils/img/icons/fluent-emoji-high-contrast_handbag.svg);
  background-size: 25px !important;
  background-repeat: no-repeat;
  background-position: center;
}
.slider-service label .card span.cantatemizleme::before {
  background-image: url(./utils/img/icons/fluent-emoji-high-contrast_handbag.svg);
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
  top: 58%;
}

.service-selection label .card span.kurutemizleme::before {
  background-image: url(./utils//img/icons/hanger-blue.svg);
}

.service-selection-main label .card span.kurutemizleme::before {
  background-image: url(./utils//img/icons/hanger-blue.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}

.service-selection label .card span.kuru-temizleme::before {
  background-image: url(./utils//img/icons/hanger-blue.svg);
}

.service-selection label .card span.camasiryikama::before {
  background-image: url(./utils/img/icons/washing-machine-blue.svg);
}

.service-selection label .card span.camasir-yikama::before {
  background-image: url(./utils/img/icons/washing-machine-blue.svg);
}

.service-selection label .card span.utuleme::before {
  background-image: url(./utils/img/icons/iron-blue.svg);
  top: 67%;
}

.service-selection-main label .card span.utulemehizmeti::before {
  background-image: url(./utils/img/icons/iron-blue.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
  top: 60%;
}

.service-selection label .card span.utulemehizmeti::before {
  background-image: url(./utils/img/icons/iron-blue.svg);
}
.services-icon .card span.utulemehizmeti::before {
  background-image: url(./utils/img/icons/iron-white.svg);
  background-size: 30px !important;
  background-repeat: no-repeat;
  background-position: center;
}

.service-selection label input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid var(--tertiary-light-gray);
  position: absolute;
  left: 40%;
  bottom: 16px;
  z-index: 1;
  /* burda */
  display: grid;
  place-content: center;
}

.service-selection label input[type="checkbox"]:checked::before {
  content: url(./utils//img/icons/checkmark-blue.svg);
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.service-selection-main label input[type="checkbox"]:checked::before {
  content: "" !important;
  /* position: absolute;
  z-index: 1;
  left: 3px;
  top: 0px;
  width: 100%;
  height: 100%; */
}
.slider-service label input[type="checkbox"]:checked::before {
  content: "" !important;
  /* position: absolute;
  z-index: 1;
  left: 3px;
  top: 0px;
  width: 100%;
  height: 100%; */
}

.service-selection label input[type="checkbox"]:checked + .card {
  background-color: var(--primary-blue);
}

.service-selection input[type="checkbox"]:checked + .card h2 {
  color: #fff;
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.lostra::before {
  background-image: url(./utils/img/icons/shoes-white.svg);
}

label input[type="checkbox"]:checked + .card span.ayakkabi::before {
  background-image: url(./utils/img/icons/shoes-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.canta-temizleme::before {
  background-image: url(./utils/img/icons/cantatemizleme.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.cantatemizleme::before {
  background-image: url(./utils/img/icons/cantatemizleme.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.kuru-temizleme::before {
  background-image: url(./utils/img/icons/hanger-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.camasir-yikama::before {
  background-image: url(./utils/img/icons/washing-machine-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.kurutemizleme::before {
  background-image: url(./utils/img/icons/hanger-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.camasiryikama::before {
  background-image: url(./utils/img/icons/washing-machine-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.utuleme::before {
  background-image: url(./utils/img/icons/iron-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.utulemehizmeti::before {
  background-image: url(./utils/img/icons/iron-white.svg);
}

.service-selection
  label
  input[type="checkbox"]:checked
  + .card
  span.utulemehizmeti::before {
  background-image: url(./utils/img/icons/iron-white.svg);
}

.add-inputs {
  text-align: right;
  padding: 24px 0 48px 0;
}

.added-input,
.extra-input,
.delivery {
  padding: 0;
  max-height: 0;
  transition: max-height 2s, padding 1s;
  overflow: hidden;
}

.added-input.active,
.extra-input.active,
.delivery.active {
  overflow: visible;
  max-height: none;
}

.added-input.active,
.extra-input.active {
  padding: 48px 0;
}

.order-form p {
  font-size: 14px;
  line-height: 24px;
}

.order-box {
  padding: 40px 45px 45px 45px;
  /* margin-left: 40px; */
  background: #fcfcfc;
  border: 2px solid var(--primary-blue);
  border-radius: 15px;

  width: 100%;
}

.order-box p,
.order-box table tr td {
  font-size: 18px;
}

.order-box table tr td:first-of-type {
  font-weight: 600;
  padding-right: 15px;
}

.order-box ul li {
  font-size: 16px;
  margin-bottom: 20px;
}

/* REGISTER */
section.register-section {
  height: 100vh;
}

.register-left,
.register-right {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-content.forgot-password {
  margin-top: 50px;
}

.register-left {
  background-color: var(--primary-blue);
}

.register-left img {
  margin-bottom: 64px;
}

.register-left h2 {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.register-left p {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  max-width: 403px;
}

.register-content {
  min-width: 50%;
}

.register-content.max {
  max-width: 50%;
}

.register-content h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 13px;
}

.register-content p {
  font-size: 24px;
  margin-bottom: 34px;
}

.register-content p a {
  font-weight: 600;
}

.register-content form .form-check,
.register-content .form .form-check {
  padding-left: 0;
  display: flex;
  align-items: center;
}

.register-form .form-check label {
  margin-bottom: 0;
  margin-top: 2px;
}

.register-form .alert {
  max-width: 50%;
}
@media (max-width: 761px) {
  .footer-menu,
  footer h6 {
    text-align: center;
  }
}

@media (max-width: 750px) {
  .upper-footer h3 {
    font-size: 35px;
  }

  .terms-container {
    width: 80%;
  }
  .location-box {
    height: 150px;
  }
  .video-iframe {
    width: 300px !important;
  }

  .quick-search form,
  .quick-search .form {
    position: relative;
  }

  .forgot-password {
    width: 100% !important;
    /* font-size: 12px !important; */
  }

  .forgot-password h1 {
    font-size: 20px !important;
  }

  .forgot-password p {
    font-size: 12px !important;
  }

  /* .forgot-password form button {
    width: 100% !important;
  } */
  .forgot-password label {
    font-size: 12px !important;
  }

  .faq-box h3 {
    font-size: 10px !important;
    /* padding: -10px 5px 10px 5px; */
    line-height: 14px;
  }

  /* .awaiting-confirm {
    font-size: 10px !important;
  } */
  .quick-search form button,
  .quick-search .form button {
    position: absolute;
    top: 90px;
    left: 100px;
  }
}

@media only screen and (max-width: 380px) {
  .ecommerce-products {
    grid-template-columns: repeat(2, 1fr);
  }
  /* 02- HEADER */
  #slider .quick-search {
    position: absolute;
    top: 115px;
    left: 0;
  }
  .serviceImage {
    height: 400px !important;
  }
  .footerBrands {
    width: 300px;
  }
  .info-box-contact {
    width: 150px;
    height: 150px;
  }

  .info-box-contact h6 {
    font-size: 10px;
  }

  .info-box-contact p {
    font-size: 10px;
  }

  .info-box-contact img {
    width: 30px;
  }

  .contact-form-title {
    font-size: 18px !important;
  }

  .video-iframe {
    width: 250px !important;
  }

  img.contact-icons {
    width: 40px;
    padding: 13px;
    border-radius: 10px;
  }

  /* .faq-box {
    height: 95px;
  } */
  header #main-nav {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 110px;
    width: 300px;

    padding: 29px 25px;
  }

  .button-ghost.text-white {
    line-height: auto;
    padding: auto 15px;
  }

  .button {
    /* padding: 5px 15px; */
    font-size: 10px;
    border-radius: 12px;
    /* line-height: 40px; */
  }
  .button-back {
    /* padding: 10px 15px; */
    line-height: 40px;
  }
  header #main-nav {
    min-width: 200px;
  }

  .faq-box p,
  .faq-box .price-list-box-allPrices {
    padding: 0;
    margin-top: 10px !important;
  }

  .faq-box p,
  .faq-box .price-list-box-allPrices ul li p {
    font-size: 10px !important;
  }

  .faq-box .price-list-box ul li p {
    font-size: 20px !important;
  }

  .faq-box p,
  .faq-box .price-list-box-allPrices {
    line-height: 15px;
  }

  .hizmetlerimiz-container {
    padding-left: 1px !important;
  }

  .awaiting-confirm {
    font-size: 6px !important;
    top: 10px;
  }

  header nav ul > li {
    font-size: 13px;
    margin-bottom: 6px;
  }

  header nav ul > li.dropdown .dropdown-box.mega h3 {
    font-size: 13px;
  }

  header nav ul > li.dropdown .dropdown-box ul li,
  header nav ul > li.dropdown .dropdown-box.mega h3 {
    margin-bottom: 6px;
  }

  header nav ul > li.dropdown .dropdown-box.mega ul li {
    font-size: 13px;
    line-height: 15px;
  }

  /* 02- HEADER END */

  /* 06- APP */
  .app-content h3 {
    font-size: 34px;
  }

  .app-content h5 {
    font-size: 21px;
  }

  .app-buttons a:first-of-type img {
    margin-bottom: 0;
  }

  .app-buttons img {
    max-width: 150px;
  }

  /* 06- APP END */
}

@media (max-width: 768px) {
  section#process .process-box:not(:last-of-type)::after {
    left: 62px;
  }

  .ad-banner,
  .courier-banner {
    padding: 10px;
  }
  .banner-button {
    font-size: 7px !important;
    padding: 1px 6px !important;
  }
  .location-box {
    height: 200px;
  }
  .awaiting-confirm.details {
    bottom: 0;

    top: 120px;
    width: 200px;
    height: 35px;
  }
  #slider h1 {
    font-size: 32px;
  }

  #slider .slide-image {
    width: 100%;
  }

  #slider .slide-image img {
    align-self: center;
    width: 90%;
  }

  #slider .quick-search {
    width: 100%;
  }

  #slider .quick-search form,
  #slider .quick-search .form {
    display: block;
  }

  #slider .quick-search .form-group {
    text-align: center;
  }

  #slider .quick-search .form-group input {
    width: 100%;
    margin-bottom: 15px;
    padding: 12px 20px;
    text-align: center;
  }

  /* #slider .quick-search button {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
  } */

  header .mobile-menu {
    display: block;
  }

  .awaiting-confirm {
    top: 70px;
    font-size: 9px;
  }
}

/* @media (min-width: 768px) {
  #menu-overlay {
    opacity: 0;
    visibility: hidden !important;
  }
  header nav ul > li.dropdown .dropdown-box {
    display: block;
    position: absolute;
    padding-top: 15px;
    width: 2056px;
    left: 400px;
    z-index: 100;
  }
} */

/* 99- RESPONSIVE END */
