/* RadioButton.css */
.radio-button-commerce {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  border-radius: 100px;
  border: 1px solid var(--Gray-200, #c9cfd2);
  background: var(--Gray-00, #fff);
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.horizontal-line {
  background: #e4e7e9;
  width: 100%;
  height: 1px;
}
/* .side-menu {
  width: 312px;
  min-width: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: sticky;
  top: 0;
  height: 100%;
} */
.side-menu {
  width: 312px;
  min-width: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: sticky;
  top: 0;
  height: 100%;
  transition: transform 0.3s ease;
}

.menu-toggle {
  display: none;
}

.product-main-slider-container {
  max-width: 483px;
  width: 100%;
  margin: auto;
  height: 100%;
}

.close-button {
  display: none;
}
.menu-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 999;
}

.horizontal-line {
  border-bottom: 1px solid #ccc;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.filter-title {
  display: none;
}
.sort-modal {
  z-index: 100;
  position: absolute;

  top: 50px;
  padding: 29px 25px;
  min-width: 200px !important;
  padding: 29px 25px;
  background-color: #fff;
  border-radius: 24px;
  -webkit-box-shadow: 0px -1px 13px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -1px 13px 4px rgba(0, 0, 0, 0.05);
}
.sort-modal p {
  cursor: pointer;
  margin: 15px 0;
}
.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  list-style-type: none;
  gap: 10px;
}

.pagination li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  border-radius: 50%;
  background: var(--Gray-00, #fff);
  cursor: pointer;
  color: var(--Gray-900, #191c1f);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.pagination li.next {
  border: 1px var(--Primary, #3e57fe) solid;
}
.pagination li.previous {
  border: 1px var(--Primary, #3e57fe) solid;
}
.pagination li.active {
  background: var(--Primary, #3e57fe);
  color: white;
}

.pagination li a {
  text-decoration: none;
  color: inherit;
}

.pagination li.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 820px) {
  .product-main-slider-container {
    max-width: 375px;
  }
  .side-menu {
    width: 100%;
    overflow: scroll;
    position: fixed;
    border-radius: 32px 32px 0px 0px;
    background: var(--color-palette-neutral-00, #fff);
    top: 7%;
    z-index: 1099;

    left: 0;
    padding-bottom: 50px;
    transform: translateY(100%);
  }
  .close-button-container {
    background-color: var(--Gray-700);
    z-index: 1000;
  }
  .close-button {
    display: block;
    background-color: #e8e8e8;
    width: 56px;
    height: 30px;
    position: absolute;
    right: 43%;
    z-index: 100;
  }
  .side-menu.open {
    transform: translateY(0);
  }

  .filter-title {
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }
  .sort-products-container {
    display: none !important;
  }
  .menu-toggle {
    border-radius: 15px;
    border: 1px solid var(--Gray-100, #e4e7e9);
    background: var(--Gray-00, #fff);
    display: flex;
    width: 44px;
    height: 44px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .menu-toggle img {
    width: 20px;
    height: 20px;
  }

  .menu-content.hidden {
    display: none;
  }

  .menu-content.visible {
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: #fff;
    padding: 10px;
  }
}

.tags-container {
  display: flex;
  /* padding: 6px 12px; */
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.tag-item {
  cursor: pointer;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--Gray-100, #e4e7e9);
}
.tag-item.active {
  border-radius: 12px;
  border: 1px solid var(--Primary, #3e57fe);
  background: rgba(62, 87, 254, 0.15);
}
.tag-item :hover {
  background: rgba(62, 87, 254, 0.15);
}
.search-product {
  display: inline-flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  padding-right: 30px;
  width: 100%;
  gap: -111px;
  border-radius: 15px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
  color: var(--Gray-500, #77878f);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.search-container {
  position: relative;
  max-width: 365px;
}
.sort-products-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  gap: 20px;
}
.sort-products-container label {
  color: var(--Gray-900, #191c1f);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/* .sort-products-container select {
  color: var(--Gray-700, #475156);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  height: 44px;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 55px;
  border-radius: 15px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
} */
.sort-products-container select {
  color: var(--Gray-700, #475156);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  display: flex;
  height: 44px;
  padding: 12px 18px;
  padding-right: 35px; /* Increase the padding on the right to make space for the arrow */
  justify-content: center;
  align-items: center;
  gap: 55px;
  border-radius: 15px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
  position: relative; /* Needed for pseudo-element positioning */
}

.sort-products-container select::after {
  content: url(/src/utils/img/icons/chevron-down-white.svg); /* Ensure the path is correct */
  position: absolute;
  right: 20px; /* Adjust this value to give more space */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent mouse interaction */
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
}

.sort-products-container select::-ms-expand {
  display: none; /* Hide the default arrow on Internet Explorer */
}

.search-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.side-menu label {
  margin-bottom: 0;
  color: var(--Grey, #676767);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.side-menu label:hover {
  color: var(--Grey-900, #191c1f);
}
.side-menu label.active {
  font-weight: 500;

  color: var(--Dark, #2e2e2e);
}
.radio-button-container-ecommmerce {
  display: flex;
  align-items: center;
  gap: 8px;
}
.side-menu-group-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.side-menu-group-menu-items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.radio-button-commerce:checked {
  background: url("./utils/img/icons/selectedRadio.svg") no-repeat center center;
  background-size: contain;
  border: none;
}
.side-menu-title {
  color: var(--Gray-900, #191c1f);
  font-feature-settings: "clig" off, "liga" off;

  /* Button */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: o;
  text-align: left;
}
.container-auth {
  max-width: 500px;

  margin: 90px auto;
  padding: 50px 70px;
  border: 1px solid #ccc;
  border-radius: 29px;
}
.cart-icon-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 15px;
  background: var(--Grey3, #f1f1f1);
}
.cart-count {
  position: absolute;
  color: var(--Primary, #3e57fe);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  top: 22%;
  left: 56%;
}
.login-button {
  border: 3px solid var(--primary-blue) !important;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.register-button {
  color: #3e57fe;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.status-name {
  height: 40px;
  /* background-color: red; */
}
.mainImagesOrder {
  cursor: pointer;
  object-fit: contain;
  width: 100px;
  border-radius: 10px;
  max-height: 100px;
  margin-right: 10px;
}
.order-date-title {
  font-weight: bold;
  margin-right: 2px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 12px;
}
.whatsapp-container a {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}
.auth-logo-img {
  margin-left: 13px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
}
.auth-logo-img img {
  width: 70px;
}
.optionalImages {
  cursor: pointer;
  object-fit: contain;
  /* width: 100px; */
  border-radius: 10px;
  max-height: 50px;
  margin-right: 10px;
}
.brand-info {
  text-align: left;
}

.brand-info-container {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.brand-img {
  object-fit: cover;
  max-width: 100%;
  border: 3px solid #e8e8e8;
  border-radius: 30px;
  /* object-position: center; */
  width: 482px;
  /* max-width: 482px; */
  max-height: 482px;
  /* min-height: 482px; */
}

.mainImagesOrderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: opacity 0.5s ease-in-out; */
}
.terms-container {
  font-size: 12px;
  border-radius: 10px !important;
  background-color: #f1f1f1;
  width: 50%;
  height: 500px;
  overflow-y: scroll !important;
  padding: 30px 45px;
}
.form-group-auth {
  margin-bottom: 20px;
}

.faq-container {
  border-radius: 20px;
  padding: 0 60px;
}

.chat-logo {
  width: 80px;
  height: 80px;
  color: white;
}

.faq-content {
  border-radius: 20px;
  display: flex;
  padding-right: 140px;
  padding-left: 60px;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
}

.contact-button-container {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-form-title {
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
}

.main-container {
  margin: 60px 0;
}

.button-cont {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 60px;
  gap: 20px;
  flex-wrap: wrap;
}

.contact-icons {
  background-color: #007bff;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  padding: 18px;
}

.contact-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  flex-direction: row;
  /* gap: 70px; */
  /* width: 100%; */
}

.info-box-contact {
  height: 300px;
  padding: 40px;
  border: 2px solid #e8e8e8;
  border-radius: 20px;
  margin-bottom: 36px;
  background-color: #fff;
  transition: border-color 0.5s;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.contact-info-span {
  font-weight: 700;
}

.info-container {
  display: flex;
  justify-content: center;
  gap: 70px;
  flex-wrap: wrap;
  align-items: center;
}

.contact-title {
  font-weight: 600;
  color: #007bff;
  text-transform: uppercase;
}

.contact-container {
  margin-bottom: 50px;
  display: flex !important;
  gap: 30px;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.adress-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: end;
}

.order-check-box {
  display: flex;

  flex-direction: column;
  font-size: small !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  width: 150px;
  height: 150px;
}

.btn-order {
  color: #007bff !important;
  border: 2px solid #007bff !important;
  background-color: #fff;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.container-address {
  position: relative;
}

.edit-button {
  bottom: 13px;
  right: 60px;
  border: none;
  margin-right: -15px;

  padding-right: 10px;
  background-color: #f1f1f1;
  font-weight: bold;
  position: absolute;
}

.delete-button {
  background-color: #d6201d;
  right: 15px;
  bottom: 12px;
  border: none;
  border-radius: 8px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  position: absolute;
}

.icon-container-address {
  position: absolute;
  right: 0;
  display: flex;
  gap: 8px;
}

.icon-container i {
  cursor: pointer;
}

.orders-menu {
  /* width: 350px; */
  /* max-width: 350px; */
  border-radius: 15px;
  background-color: #f1f1f1b3 !important;
  padding: 35px 20px !important;
}

.order-boxes {
  background-color: #f1f1f1b3 !important;
  padding: 20px 25px;
  border-radius: 15px;
}
.order-boxes-mobile {
  background-color: #f1f1f1b3 !important;
  padding: 20px 25px;
  display: flex;
  border-radius: 15px;
  gap: 40px;
}

.status-container {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 20px;
  font-size: 12px;
}

.status-container p {
  font-weight: 600;
  font-size: 12px;
}

.status-container span {
  font-weight: 600;
  font-size: 12px;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}

.order-user-menu {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 25px;
}

.order-titles {
  display: flex;
  padding-bottom: 5px;
  gap: 30px;
  margin: 0;
}

.menu-item h5 {
  text-decoration: none;
  cursor: pointer;
  color: black;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.2s ease-in-out;
}

.menu-item .active {
  color: #007bff;
  border-bottom-color: #007bff;
}

.order-user-menu span {
  color: #000;
  font-weight: 700;
}
.carousel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; /* Adjust the padding as needed */
}

.text-container {
  width: 40%; /* Adjust the width as needed */
}

.carousel-wrapper {
  width: 60%; /* Adjust the width as needed */
}

.error-auth {
  color: red;
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
}

.success-alert {
  top: 120px;
  right: 80px;
  border: none;
  border-radius: 18px;
  background: #2ecc71;
  color: #ffffff;
  /* color: #000; */
  position: fixed;
  /* background-color: #31f05ac2; */
  font-weight: 600;
}

.auth-button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.sub-text {
  font-size: 12px;
  font-weight: lighter;
  color: #2e2e2e;
}
.sub-text-bold {
  font-size: 12px;
  font-weight: bold;
  color: #2e2e2e;
}

.button-primary {
  color: #fff;
  background-color: var(--primary-blue);
  border: none;
}

.button-primary-outline {
  padding: 0 80px !important;
  color: var(--primary-blue);
  background-color: #fff;
  text-transform: uppercase;
  font-size: 22px !important;
  border: 3px solid var(--primary-blue) !important;
}

.button-primary-outline:hover {
  background-color: var(--primary-blue);
  color: #fff;
}

.location-chips {
  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 16px !important;
  padding: 12px 18px !important;
  color: #676767;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.order-cancel {
  background-color: #d6201d;
}
.order-cancel-mobile {
  background-color: #d6201d;
  /* width: 50px;
  height: 50px; */
}

.order-cancel img {
  width: 20px;
  padding: 2px;
  height: 12px;
  border-radius: 5px;
}
.order-cancel-mobile,
.order-done-mobile {
  padding: 10px;
  border-radius: 30%;
}
.icon-container img {
  width: 20px;
  height: 20px;
}

.activeMenu {
  color: #007bff !important;
}

.light-text {
  font-weight: lighter;
}

.status-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.ç {
  cursor: pointer;
}
.order-done {
  background-color: #46f86d;
  /* width: 40px; */
}
.order-done-mobile {
  background-color: #46f86d;
  /* width: 40px; */
}
.order-done-mobile img,
.order-cancel-mobile img {
  width: 25px;
  padding: 1px;
  height: 25px;
}
/* .order-cancel {
  background-color: #d6201d;
  width: 40px;
  height: 40px;
} */
.delete-button-modal {
  border: none;

  background-color: #d6201d !important;
}

.blog-card {
  padding: 16px;
  text-align: left;
  max-width: 450px;
}

.blog-card h2 {
  font-size: 18px;
  margin-bottom: 8px;
}

.blog-card p {
  max-width: 379px;
  overflow-wrap: break-word;
  font-size: 14px;
  /* height: 80px; */
  text-align: left;
  color: #676767;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  overflow: hidden;
}

.blog-card p::after {
  content: "...";
}

.blog-card button {
  margin-top: 8px;
}

.information-img {
  position: relative;
  cursor: pointer;
}

.popup-img {
  position: absolute;
  left: 20px;
  width: 100px;
  object-fit: cover;
  object-position: center;
  display: none;
  bottom: 10px;
}

.popup-img img {
  border-radius: 15px;

  width: 100%;
}

.information-img:hover .popup-img {
  display: block;
}

.order-offer-container {
  background-color: #f1f1f1;
  padding: 30px 45px;
  border-radius: 15px;
  position: relative;
}

.blog-images-container {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
}

.blog-images {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 410px;
  height: 256px;
  min-height: 126px;
}

.blog-images img {
  border-radius: 26px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  /* max-height: 250px; */
  height: 250px;
}

.blogContent {
  text-align: left;
  color: #676767;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.blog-cover {
  /* display: block; */
  /* margin: 0 auto; */
  border-radius: 26px;
  object-fit: cover;
  width: 100%;
  max-height: 460px;
  /* max-width: 1100px; */
}

.smallInfo {
  color: #2e2e2e;
  display: block;
  font-size: smaller;
  font-weight: lighter;
  margin-top: -15px;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.5);
}

.modal-container {
  border-radius: 19px;
  padding: 10px 15px;

  border: 1px solid rgba(0, 0, 0, 0.111);
}

.confirm-order-container span {
  font-weight: 700 !important;
  color: black !important;
}

.mainImagesOrderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s ease-in-out;
}

.slideshowContainer {
  position: relative !important;
  width: 400px;
}
.serviceimgcontainer {
  position: relative !important;
}

.slideshowNavigation {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transform: translateY(-50%);
}

.slideshowArrow {
  color: white;
  font-size: 24px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.slideshowArrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.custom-file {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  border: 1px dashed #ccc;
  padding: 40px 50px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #4d66ff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.whatsapp-container {
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  height: 60px;
  bottom: 20px;
  right: 0;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #5bd366 0%, #26b43d 100%);
  border-radius: 38.5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: width 0.5s;
}

.whatsapp-container:hover {
  width: 150px;
}

.whatsapp-container:hover .whatsapp-message {
  display: block;
  opacity: 1;
  transform: translateX(10px);
}

.whatsapp-message {
  display: none;
  color: white;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
}

.whatsapp-container img {
  width: 35px;
}

@media (max-width: 300px) {
  .order-titles {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .status-name {
    height: 30px;
  }
  .blog-images img {
    height: 150px;
  }

  .blog-images-container {
    height: 200px;
  }
  .order-boxes {
    font-size: 8px;
  }
  .order-boxes p {
    font-size: 8px;
  }
  .order-boxes span {
    font-size: 8px;
  }

  /* .order-boxes {
    font-size: 12px;
  }

  .order-boxes span,
  .order-boxes p {
    font-size: 12px;
  } */
  .status-container {
    gap: 5px;
  }
}

@media (max-width: 480px) {
  /* .status-container {
    flex-direction: column;
  } */

  .awaiting-confirm.details {
    bottom: 0 !important;

    top: 90px !important;
    height: 25px !important;
    /* background-color: #000 !important; */
    width: 160px !important;
  }

  .status-name {
    height: 20px;
  }
  .order-boxes {
    /* width: 80%; */
    font-size: 10px;
  }

  .blog-images img {
    height: 100px;
  }

  .blog-images-container {
    height: 100px;
  }

  .order-boxes span,
  .order-boxes p {
    font-size: 6px;
  }

  .status-container {
    gap: 2px;
  }

  .icon-container {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }
  /* .order-cancel {
    width: 15px;
    height: 15px;
    border-radius: 5px;
  } */
  .icon-container img {
    width: 10px;
    height: 10px;
  }
  .order-done img,
  .order-cancel img {
    width: 10px;
    padding: 1px;
    height: 6px;
  }
}
@media (max-width: 460px) {
  .order-boxes {
    /* width: 80%; */
    font-size: 8px;
  }
  .total-sum {
    height: 15px !important;
  }
  .order-boxes span {
    font-size: 6px;
    margin-top: 3px;
  }

  .status-container {
    gap: 1px;
  }

  .icon-container {
    min-width: 15px;
    width: 15px;
    height: 15px;
    border-radius: 3px;
  }

  .icon-container img {
    width: 7px;
    height: 7px;
  }
}

.location-link.active {
  color: #007bff;
}

.location-link {
  font-weight: bold !important;
  cursor: pointer;
}

.location-link:hover {
  color: #007bff;
}

.socialContainer * {
  margin: 0;
  padding: 0;
}

.socialContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.socialButton {
  background-color: #f8f8f8;
  border: none;
  text-align: center;
  border-radius: 15px;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.socialButton .text {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

/* @media (min-width: 1024px) {
  .order-boxes {
    font-size: 10px !important;
  }
  .order-boxes p {
    font-size: 10px !important;
  }
  .order-boxes span {
    font-size: 10px !important;
  }
} */
